import React, {useState,useEffect} from 'react'
import { useTranslation } from "react-i18next";
import { H1, SubTitle,LeftRightContainer, ImageColumn, TextColumn} from '../../SharedComponents/sharedComponents'
import { CientLogo, ClientsWrapper } from './clientsElements'

import img1 from '../../../assets/clientLogos/promasidor.png'
import img2 from '../../../assets/clientLogos/danone.png'
import img3 from '../../../assets/clientLogos/dhl.png'
import img4 from '../../../assets/clientLogos/emploitic.png'
import img5 from '../../../assets/clientLogos/frater_razes.png'
import img6 from '../../../assets/clientLogos/heetch.png'
import img7 from '../../../assets/clientLogos/lafarge.png'
import img8 from '../../../assets/clientLogos/lesaffre.png'
import img9 from '../../../assets/clientLogos/novo_nordisk.png'
import img10 from '../../../assets/clientLogos/pharmaliance.png'
import img11 from '../../../assets/clientLogos/ooredoo.png'
import img12 from '../../../assets/clientLogos/totalenergies.png'
import img13 from '../../../assets/clientLogos/ald.png'
import img14 from '../../../assets/clientLogos/allegorie.png'
import img15 from '../../../assets/clientLogos/awane.png'
import img16 from '../../../assets/clientLogos/citibank.png'
import img17 from '../../../assets/clientLogos/ey.png'
import img18 from '../../../assets/clientLogos/hyatt.png'
import img19 from '../../../assets/clientLogos/hydrapharm.png'
import img20 from '../../../assets/clientLogos/icosnet.png'
import img21 from '../../../assets/clientLogos/inphamedis.png'
import img22 from '../../../assets/clientLogos/nestle.png'
import img23 from '../../../assets/clientLogos/renault.png'
import img24 from '../../../assets/clientLogos/savola.png'
import img25 from '../../../assets/clientLogos/terraco_blue.png'
import img26 from '../../../assets/clientLogos/tm_agency.png'
import img27 from '../../../assets/clientLogos/jti.png'
import img28 from '../../../assets/clientLogos/oasis.png'
import img29 from '../../../assets/clientLogos/sophal.png'
import img30 from '../../../assets/clientLogos/csee.png'
import img31 from '../../../assets/clientLogos/interactiveo.png'
import img32 from '../../../assets/clientLogos/masterbuilders.png'
import img33 from '../../../assets/clientLogos/renauld.png'
import img34 from '../../../assets/clientLogos/sald.png'
import img35 from '../../../assets/clientLogos/sgs.png'
import img36 from '../../../assets/clientLogos/pixelinvest.png'
import img37 from '../../../assets/clientLogos/merck.png'
import img38 from '../../../assets/clientLogos/systhen.png'
import img39 from '../../../assets/clientLogos/bat.png'
import img40 from '../../../assets/clientLogos/ramy.png'
import img41 from '../../../assets/clientLogos/biomerieux.png'
import img42 from '../../../assets/clientLogos/gpa.png'
import img43 from '../../../assets/clientLogos/vecopharm.png'
import img44 from '../../../assets/clientLogos/beker.png'
import img45 from '../../../assets/clientLogos/bktelecom.png'
import img46 from '../../../assets/clientLogos/elkendi.png'
//import img47 from '../../../assets/clientLogos/hupppharma.png'
import img48 from '../../../assets/clientLogos/ifruit.png'
import img49 from '../../../assets/clientLogos/pirelations.png'
import img50 from '../../../assets/clientLogos/saidal.png'
import img51 from '../../../assets/clientLogos/smollan.png'
import img52 from '../../../assets/clientLogos/henkel.png'
import img53 from '../../../assets/clientLogos/alpharep.png'
import img54 from '../../../assets/clientLogos/cciaf.png'
import img55 from '../../../assets/clientLogos/labonedjma.png'
import img56 from '../../../assets/clientLogos/repsol.png'
import img57 from '../../../assets/clientLogos/ge.png'
import img58 from '../../../assets/clientLogos/imc.png'
import img59 from '../../../assets/clientLogos/cocacola.png'
import img60 from '../../../assets/clientLogos/lg.png'
import img61 from '../../../assets/clientLogos/drreddys.png'
import img62 from '../../../assets/clientLogos/beyn.png'

import img63 from '../../../assets/clientLogos/boehringer.png'
import img64 from '../../../assets/clientLogos/takeda.png'
import img65 from '../../../assets/clientLogos/petrofac.png'
import img66 from '../../../assets/clientLogos/amsaudit.png'

import img67 from '../../../assets/clientLogos/total_lubrifiant.png'
import img68 from '../../../assets/clientLogos/translev.png'
import img69 from '../../../assets/clientLogos/sodexo.png'
import img70 from '../../../assets/clientLogos/timac.png'
import img71 from '../../../assets/clientLogos/redsli.png'
import img72 from '../../../assets/clientLogos/pfizer.png'
import img73 from '../../../assets/clientLogos/pegaz.png'
import img74 from '../../../assets/clientLogos/pea.png'
import img75 from '../../../assets/clientLogos/oxxo.png'
import img76 from '../../../assets/clientLogos/nokia.png'
import img77 from '../../../assets/clientLogos/new_rest.png'
import img78 from '../../../assets/clientLogos/meklati.png'
import img79 from '../../../assets/clientLogos/ims.png'
import img80 from '../../../assets/clientLogos/huawei.png'
import img81 from '../../../assets/clientLogos/grant_thornton.png'
import img82 from '../../../assets/clientLogos/cmscgm.png'
import img83 from '../../../assets/clientLogos/aventure.png'
import img84 from '../../../assets/clientLogos/amgen.png'
import img85 from '../../../assets/clientLogos/abc_bank.png'
import img86 from '../../../assets/clientLogos/gig.png'

import img87 from '../../../assets/clientLogos/generic_lab.png'
import img88 from '../../../assets/clientLogos/mia.png'
import img89 from '../../../assets/clientLogos/kepler_technologies.png'
import img90 from '../../../assets/clientLogos/holcim.png'
import img91 from '../../../assets/clientLogos/airp.png'

import img92 from '../../../assets/clientLogos/bingo.png'
import img93 from '../../../assets/clientLogos/ifri.png'
import img94 from '../../../assets/clientLogos/neomedic.png'
import img95 from '../../../assets/clientLogos/setif_medic_2.png'
import img96 from '../../../assets/clientLogos/lilium_pharma.png'

import img97 from '../../../assets/clientLogos/astrazeneca.png'
import img98 from '../../../assets/clientLogos/bm_promotion.png'
import img99 from '../../../assets/clientLogos/boehringer_ingelheim.png'
import img100 from '../../../assets/clientLogos/celia.png'
import img101 from '../../../assets/clientLogos/chocodada.png'
import img102 from '../../../assets/clientLogos/eccbc.png'
import img103 from '../../../assets/clientLogos/hammer_group.png'
import img104 from '../../../assets/clientLogos/oxymor.png'
import img105 from '../../../assets/clientLogos/president.png'
import img106 from '../../../assets/clientLogos/saf_instant.png'
import img107 from '../../../assets/clientLogos/siemens_energy.png'
import img108 from '../../../assets/clientLogos/slb.png'
import img109 from '../../../assets/clientLogos/somepharm.png'
import img110 from '../../../assets/clientLogos/soprodim.png'
import img111 from '../../../assets/clientLogos/vitalcare.png'



import { AnimationOnScroll } from 'react-animation-on-scroll'


const ClientSection = () => {
 
  const [logo1, setLogo1] = useState(img11);
  const [logo2, setLogo2] = useState(img2);
  const [logo3, setLogo3] = useState(img3);
  const [logo4, setLogo4] = useState(img4);
  const [logo5, setLogo5] = useState(img5);
  const [logo6, setLogo6] = useState(img6);
  const [logo7, setLogo7] = useState(img7);
  const [logo8, setLogo8] = useState(img8);
  const [logo9, setLogo9] = useState(img9);
  const [logo10, setLogo10] = useState(img10);
  const [logo11, setLogo11] = useState(img1);
  const [logo12, setLogo12] = useState(img12);


  useEffect(() => {
    const functions =[setLogo1,setLogo2,setLogo3,setLogo4,setLogo5,setLogo6,setLogo7,setLogo8,setLogo9,setLogo10,setLogo11,setLogo12];
    const pics=[img13,img14,img15,img16,img17,img18,img19,img20,img21,img22,img23,img24,img25,img26,img27,img28,img29,img30,img31,img32,img33,img34,img35,img36,img37,img38,img39,img40,img41,img42,img43,img44,img45,img46,img48,img49,img50,img51,img52,img53,img54,img55,img56,img57,img58,img59,img60,img61,img62,img63,img64,img65,img66,img67,img68,img69,img70,img71,img72,img73,img74,img75,img76,img77,img78,img79,img80,img81,img82,img83,img84,img85,img86,img87,img88,img89,img90,img91,img92,img93,img94,img95,img96,img97,img98,img99,img100,img101,img102,img103,img104,img105,img106,img107,img108,img109,img110,img111,img11,img2,img3,img4,img5,img6,img7,img8,img9,img10,img1,img12];
    let indexf=0;
    let indexp=0;
    const change=()=>{
      
      functions[indexf](pics[indexp]);
      indexf=(indexf +1) % functions.length;
      indexp=(indexp +1) % pics.length;
     
    };

    const interval = setInterval(() => {
      
      change();
    }, 800);

    return () => clearInterval(interval);
  }, []);

  const { t } = useTranslation();

  
  return (
    <>
    <AnimationOnScroll animateIn="animate__fadeInUp" delay={2} animateOnce={1}>
    <LeftRightContainer>
        <TextColumn>
            <H1>{t('landingpage-client-title')}</H1>
            <SubTitle>{t('landingpage-client-subtitle')}</SubTitle>
        </TextColumn>
        <ImageColumn>
             <ClientsWrapper>

                  <CientLogo img={logo1}  ></CientLogo>   
                  <CientLogo img={logo2}  ></CientLogo>   
                  <CientLogo img={logo3}  ></CientLogo>   
                  <CientLogo img={logo4}  ></CientLogo>   
                  <CientLogo img={logo5}  ></CientLogo>   
                  <CientLogo img={logo6}  ></CientLogo>   
                  <CientLogo img={logo7}  ></CientLogo>   
                  <CientLogo img={logo8}  ></CientLogo>   
                  <CientLogo img={logo9}  ></CientLogo>   
                  <CientLogo img={logo10}  ></CientLogo>   
                  <CientLogo img={logo11}  ></CientLogo>   
                  <CientLogo img={logo12}  ></CientLogo>   

             </ClientsWrapper>
        </ImageColumn>
    </LeftRightContainer>
    </AnimationOnScroll>
   
    </>
  )
}

export default ClientSection
