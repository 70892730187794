//import zaraLogo from "../../../assets/partnersLogos/zara_logo.png";
import guessLogo from "../../../assets/partnersLogos/guess_logo.png";
//import massimoduttiLogo from "../../../assets/partnersLogos/massimo_dutti_logo.png";
import ovsKidsLogo from "../../../assets/partnersLogos/ovs_kids_logo.png";
import hugoBossLogo from "../../../assets/partnersLogos/hugo_boss_logo.png";
import paulSharkLogo from "../../../assets/partnersLogos/paul_shark_logo.png";
import zippyLogo from "../../../assets/partnersLogos/zippy_logo.png";
import leNomadeLogo from "../../../assets/partnersLogos/le_nomade_logo.png";
import camperLogo from "../../../assets/partnersLogos/camper_logo.png";
import stepmodeLogo from "../../../assets/partnersLogos/stepmode_logo.png";
import justfootLogo from "../../../assets/partnersLogos/justfoot_logo.png";
import unibrandzLogo from "../../../assets/partnersLogos/unibrandz_logo.png";
import sneaksaLogo from "../../../assets/partnersLogos/sneaksa_logo.png";
import kenziLogo from "../../../assets/partnersLogos/kenzi_new_home_logo.png";
import nidangeLogo from "../../../assets/partnersLogos/nidange_logo.png";
import limaCareLogo from "../../../assets/partnersLogos/lima_care_logo.png";
import cardinalLogo from "../../../assets/partnersLogos/cardinal_market_logo.png";
import extraElectromenagerLogo from "../../../assets/partnersLogos/extra_electromenager_logo.png";
import gyplusLogo from "../../../assets/partnersLogos/gyplus_logo.png";
import maisoniaLogo from "../../../assets/partnersLogos/maizonia_logo.png";
import djazMarketLogo from "../../../assets/partnersLogos/djaz_market_logo.png";
import advancedOfficeLogo from "../../../assets/partnersLogos/advanced_office_logo.png";
import eyewearLogo from "../../../assets/partnersLogos/eyewear_logo.png";
import casioLogo from "../../../assets/partnersLogos/watch_center_logo.png";
import sugarLogo from "../../../assets/partnersLogos/sugar_logo.png";
//import nexttimesLogo from "../../../assets/partnersLogos/next_times_logo.png";
//import sunglassHutLogo from "../../../assets/partnersLogos/sunglass_hut_logo.png";
import maisonDeLaCravateLogo from "../../../assets/partnersLogos/maison_de_la_cravate_logo.png";
import homeYouLogo from "../../../assets/partnersLogos/home_you_logo.png";
import laCigogneDailleurLogo from "../../../assets/partnersLogos/la_cigogne_dailleur_logo.png";
import flowerBySaidaLogo from "../../../assets/partnersLogos/flowers_by_saida_logo.png";
import pioveLogo from "../../../assets/partnersLogos/piove_logo.png";
import basicsCosmeticLogo from "../../../assets/partnersLogos/basics_cosmetic_logo.png";
//import flexClub99Logo from "../../../assets/partnersLogos/flex_on_club99_logo.png";
import cityFootLogo from "../../../assets/partnersLogos/city_foot_logo.png";
import oshakraLogo from "../../../assets/partnersLogos/ochakra_logo.png";
import familiShopLogo from "../../../assets/partnersLogos/famili_shop_logo.png";
import touattiLogo from "../../../assets/partnersLogos/le_ttouati_logo.png";
import smarketLogo from "../../../assets/partnersLogos/s_market_logo.png";
import oxygenLogo from "../../../assets/partnersLogos/oxygen_logo.png";
import manzilLogo from "../../../assets/partnersLogos/manzil_logo.png";
import gresleyLogo from "../../../assets/partnersLogos/gresley_logo.png";
import mustLogo from "../../../assets/partnersLogos/must_logo.png";
import casaShoppingLogo from "../../../assets/partnersLogos/casa_shopping_logo.png";
//import easywayLogo from "../../../assets/partnersLogos/easyway_logo.png";
import unihoopsLogo from "../../../assets/partnersLogos/unihoops_logo.png";
import armaniExchangeLogo from "../../../assets/partnersLogos/armani_exchange_logo.png";
import jakamenLogo from "../../../assets/partnersLogos/jakamen_logo.png";
import xintLogo from "../../../assets/partnersLogos/xent_logo.png";
import iconceptLogo from "../../../assets/partnersLogos/iconcept_logo.png";
import leGrandMagrebLogo from "../../../assets/partnersLogos/le_grand_magreb_logo.png";
import liderMarketLogo from "../../../assets/partnersLogos/lider_market_logo.png";
import mayoralLogo from "../../../assets/partnersLogos/mayoral_logo.png";
import majesticWorldCenterLogo from "../../../assets/partnersLogos/majestic_world_center_logo.png";
//import pullandbearLogo from "../../../assets/partnersLogos/pullandbear_logo.png";
//import bershkaLogo from "../../../assets/partnersLogos/bershka_logo.png";
//import stradivariusLogo from "../../../assets/partnersLogos/stradivarius_logo.png";
//import oyshoLogo from "../../../assets/partnersLogos/oysho_logo.png";
//import zaraHomeLogo from "../../../assets/partnersLogos/zara_home_logo.png";
import footlandLogo from "../../../assets/partnersLogos/footland_logo.png";
import clarksLogo from "../../../assets/partnersLogos/clarks_logo.png";
import skechersLogo from "../../../assets/partnersLogos/sketchers_logo.png";
import parapharmTarzaali from "../../../assets/partnersLogos/parapharm_taghzali_logo.png";
import bleuBlancLogo from "../../../assets/partnersLogos/bleu_blanc_logo.png";
import orchestraLogo from "../../../assets/partnersLogos/orchestra_logo.png";
import electroramaLogo from "../../../assets/partnersLogos/electrorama_logo.png";
import electRoroyalLogo from "../../../assets/partnersLogos/electroroyal_logo.png";
import insagLogo from "../../../assets/partnersLogos/insag_logo.png";
//import dojoLogo from "../../../assets/partnersLogos/dojo_logo.png";
import promodLogo from "../../../assets/partnersLogos/promod_logo.png";
import airstreetLogo from "../../../assets/partnersLogos/air_street_logo.png";
import familiParcLogo from "../../../assets/partnersLogos/famili_parc_logo.png";
import myfitboxLogo from "../../../assets/partnersLogos/myfit_box_logo.png";
import akramNourLogo from "../../../assets/partnersLogos/akram_nour_logo.png";
import metalSportLogo from "../../../assets/partnersLogos/metal_sport_logo.png";
import idealBrandLogo from "../../../assets/partnersLogos/ideal_brand_logo.png";
import ironParkLogo from "../../../assets/partnersLogos/ironpark_logo.png";
import familyDayLogo from "../../../assets/partnersLogos/family_day_logo.png";
import alphaGymLogo from "../../../assets/partnersLogos/alpha_gym_logo.png";
//import gsportLogo from "../../../assets/partnersLogos/gsportlogo.png";
import alcLogo from "../../../assets/partnersLogos/alc_logo.png";
import profootPlusLogo from "../../../assets/partnersLogos/profoot_plus_logo.png";
import egdLogo from "../../../assets/partnersLogos/egd_logo.png";
import pmgLogo from "../../../assets/partnersLogos/pmg_logo.png";
import victoryHandbagLogo from "../../../assets/partnersLogos/victory_handbag_logo.png";
//import birkenstockLogo from "../../../assets/partnersLogos/birkenstock_logo.png";
import dimLogo from "../../../assets/partnersLogos/dim_logo.png";
import lacosteLogo from "../../../assets/partnersLogos/lacoste_logo.png";
import levisLogo from "../../../assets/partnersLogos/levis_logo.png";
import timberlandLogo from "../../../assets/partnersLogos/timberland_logo.png";
import tommyHilfigerLogo from "../../../assets/partnersLogos/tommy_hilfiger_logo.png";
import thurayaLandLogo from "../../../assets/partnersLogos/thurayaland_logo.png";
import zoneSportLogo from "../../../assets/partnersLogos/sport_zone_logo.png";
import pepeJeansLogo from "../../../assets/partnersLogos/pepe_jeans_logo.png";
import kiabiLogo from "../../../assets/partnersLogos/kiabi_logo.png";
import samsungLogo from "../../../assets/partnersLogos/samsung_logo.png";
import eryamLogo from "../../../assets/partnersLogos/eryam_logo.png";
import starPeintureLogo from "../../../assets/partnersLogos/star_peinture_logo.png";
//import mayferLogo from "../../../assets/partnersLogos/mayfer_logo.png";
import maisonDuSavonLogo from "../../../assets/partnersLogos/maison_du_savon_logo.png";
import mformatiqueLogo from "../../../assets/partnersLogos/mformatique_logo.png";
import courirLogo from "../../../assets/partnersLogos/courir_logo.png";
import hisenseLogo from "../../../assets/partnersLogos/hisense_logo.png";
import areejLogo from "../../../assets/partnersLogos/areej_logo.png";
import areejMarketLogo from "../../../assets/partnersLogos/areej_market_logo.png";
import leComptoirDuChocolatLogo from "../../../assets/partnersLogos/le_comptoir_du_chocolat_logo.png";
import etamLogo from "../../../assets/partnersLogos/etam_logo.png";
import aldoLogo from "../../../assets/partnersLogos/aldo_logo.png";
import undizLogo from "../../../assets/partnersLogos/undiz_logo.png";
import callItSpringLogo from "../../../assets/partnersLogos/call_it_spring_logo.png";
import lumiramaLogo from "../../../assets/partnersLogos/lumirama_logo.png";
import bbeLogo from "../../../assets/partnersLogos/bbe_logo.png";
import pentiLogo from "../../../assets/partnersLogos/penti_logo.png";
import tatouLogo from "../../../assets/partnersLogos/tatou_logo.png";
import kotonLogo from "../../../assets/partnersLogos/koton_logo.png";
import dsdamatLogo from "../../../assets/partnersLogos/dsdamat_logo.png";
import kigiliLogo from "../../../assets/partnersLogos/kigili_logo.png";
import maviLogo from "../../../assets/partnersLogos/mavi_logo.png";
import pancoLogo from "../../../assets/partnersLogos/panco_logo.png";
import suwenLogo from "../../../assets/partnersLogos/suwen_logo.png";
import stylkomLogo from "../../../assets/partnersLogos/stylkom_logo.png";
import setreLogo from "../../../assets/partnersLogos/setre_logo.png";
import ninewestLogo from "../../../assets/partnersLogos/ninewest_logo.png";
import oscarLogo from "../../../assets/partnersLogos/oscar_logo.png";
import siliceHouseLogo from "../../../assets/partnersLogos/silice_house_logo.png";
import athatLogo from "../../../assets/partnersLogos/athat_logo.png";
import doodlesLogo from "../../../assets/partnersLogos/doodles_logo.png";
//import cosmibelleLogo from "../../../assets/partnersLogos/cosmibelle_logo.png";
import gntLogo from "../../../assets/partnersLogos/gnt_logo.png";
import lePrintempsLogo from "../../../assets/partnersLogos/le_printemps_logo.png";
import esperanzaLogo from "../../../assets/partnersLogos/esperanza_logo.png";
import hugeLogo from "../../../assets/partnersLogos/huge_logo.png";
import rosalgerinaLogo from "../../../assets/partnersLogos/rosalgerina_logo.png";
import maison123Logo from "../../../assets/partnersLogos/maison_123_logo.png";
import dreamHouseLogo from "../../../assets/partnersLogos/dream_house_logo.png";
import cacharelLogo from "../../../assets/partnersLogos/cacharel_logo.png";
import pribaLogo from "../../../assets/partnersLogos/priba_logo.png";
import shapesLogo from "../../../assets/partnersLogos/shapes_logo.png";
//import nardiLogo from "../../../assets/partnersLogos/nardi_logo.png";
import marjoryLogo from "../../../assets/partnersLogos/marjory_logo.png";
import arveaLogo from "../../../assets/partnersLogos/arvea_logo.png";
import europaSportLogo from "../../../assets/partnersLogos/europa_sport_logo.png";
import benettonLogo from "../../../assets/partnersLogos/benetton_logo.png";
import jackJonesLogo from "../../../assets/partnersLogos/jack_jones_logo.png";
import megaConfortLogo from "../../../assets/partnersLogos/mega_confort_logo.png";
import acaciaHomeDesignLogo from "../../../assets/partnersLogos/acacia_home_design_logo.png";
//import usPoloAssnLogo from "../../../assets/partnersLogos/us_polo_assn_logo.png";
import gomodeLogo from "../../../assets/partnersLogos/gomode_logo.png";
import thomsonLogo from "../../../assets/partnersLogos/thomson_logo.png";
import coolClubLogo from "../../../assets/partnersLogos/cool_club_logo.png";
import cityStyleLogo from "../../../assets/partnersLogos/city_style_logo.jpg";
import tafsutLogo from "../../../assets/partnersLogos/tafsut_logo.png";
import lcWaikikiLogo from "../../../assets/partnersLogos/lc_waikiki_logo.png";
import celioLogo from "../../../assets/partnersLogos/celio_logo.png";
import mangoLogo from "../../../assets/partnersLogos/mango_logo.png";
import barakiMallLogo from "../../../assets/partnersLogos/baraki_mall_logo.png";





import basicsCosmeticCover from "../../../assets/partnersCovers/basics_cover.png";
import advancedOfficeCover from "../../../assets/partnersCovers/advanced_office_cover.png";
import HugobossCover from "../../../assets/partnersCovers/boss_cover.png";
import camperCover from "../../../assets/partnersCovers/camper_cover.png";
import cardinalCover from "../../../assets/partnersCovers/cardinal_cover.png";
import casioCover from "../../../assets/partnersCovers/casio_cover.png";
import cityFootCover from "../../../assets/partnersCovers/city_foot_cover.png";
//import flexClub99Cover from "../../../assets/partnersCovers/flex_club_99_cover.png";
import extraElectromenagerCover from "../../../assets/partnersCovers/extra_cover.png";
import eyewearCover from "../../../assets/partnersCovers/eye_wear_cover.png";
import guessCover from "../../../assets/partnersCovers/guess_cover.png";
import gyplusCover from "../../../assets/partnersCovers/gyplus_cover.png";
import homeYouCover from "../../../assets/partnersCovers/home_you_cover.png";
import justfootCover from "../../../assets/partnersCovers/just_foot_cover.png";
import kenziCover from "../../../assets/partnersCovers/kenzi_cover.png";
import limaCareCover from "../../../assets/partnersCovers/lima_care_cover.png";
import laCigogneDailleurCover from "../../../assets/partnersCovers/la_cigogne_dailleur_cover.png";
import leNomadeCover from "../../../assets/partnersCovers/le_nomade_cover.png";
import maisonDeLaCravateCover from "../../../assets/partnersCovers/maison_de_la_cravate_cover.png";
//import massimoduttiCover from "../../../assets/partnersCovers/massimo_dutti_cover.png";
import nidangesCover from "../../../assets/partnersCovers/nid_anges_cover.png";
import ovsKidsCover from "../../../assets/partnersCovers/ovs_kids_cover.png";
import paulSharkCover from "../../../assets/partnersCovers/paul_shark_cover.png";
import pioveCover from "../../../assets/partnersCovers/piove_cover.png";
import flowersBySaidaCover from "../../../assets/partnersCovers/saida_flowers_cover.png";
import smarketCover from "../../../assets/partnersCovers/smarket_cover.png";
import sneaksaCover from "../../../assets/partnersCovers/sneaksa_cover.png";
import stepmodeCover from "../../../assets/partnersCovers/stepmode_cover.png";
import sugarCover from "../../../assets/partnersCovers/sugar_cover.png";
//import sunglassHutCover from "../../../assets/partnersCovers/sunglass_hut_cover.png";
import touattiCover from "../../../assets/partnersCovers/touatti_cover.png";
import unibrandsCover from "../../../assets/partnersCovers/unibrands_cover.png";
//import zaraCover from "../../../assets/partnersCovers/zara_cover.png";
import zippyCover from "../../../assets/partnersCovers/zippy_cover.png";
import familiShopCover from "../../../assets/partnersCovers/famili_shop_cover.png";
import maisoniaCover from "../../../assets/partnersCovers/maisonia_cover.png";
import djazMarketCover from "../../../assets/partnersCovers/djaz_market_cover.png";
import oshakraCover from "../../../assets/partnersCovers/oshakra_cover.png";
//import nextTimesCover from "../../../assets/partnersCovers/nexttimes_cover.png";
import oxygenCover from "../../../assets/partnersCovers/oxygen_cover.png";
import manzilCover from "../../../assets/partnersCovers/manzil_cover.png";
import gresleyCover from "../../../assets/partnersCovers/gresley_cover.png";
import casaCover from "../../../assets/partnersCovers/casa_cover.png";
import mustCover from "../../../assets/partnersCovers/must_cover.png";
//import easywayCover from "../../../assets/partnersCovers/easyway_cover.png";
import unihoopsCover from "../../../assets/partnersCovers/unihoops_cover.png";
import armaniExchangeCover from "../../../assets/partnersCovers/armani_exchange_cover.png";
import jakamenCover from "../../../assets/partnersCovers/jakamen_cover.png";
import xintCover from "../../../assets/partnersCovers/xint_cover.png";
import iconceptCover from "../../../assets/partnersCovers/iconcept_cover.png";
import leGrandMagrebCover from "../../../assets/partnersCovers/le_grand_magreb_cover.png";
import liderMarketCover from "../../../assets/partnersCovers/lider_market_cover.png";
import mayoralCover from "../../../assets/partnersCovers/mayoral_cover.png";
import majesticWorldCenterCover from "../../../assets/partnersCovers/majestic_world_center_cover.png";
//import pullandbearCover from "../../../assets/partnersCovers/pullandbear_cover.png";
//import bershkaCover from "../../../assets/partnersCovers/bershka_cover.png";
//import stradivariusCover from "../../../assets/partnersCovers/stradivarius_cover.png";
//import oyshoCover from "../../../assets/partnersCovers/oysho_cover.png";
//import zaraHomeCover from "../../../assets/partnersCovers/zara_home_cover.png";
import footlandCover from "../../../assets/partnersCovers/footland_cover.png";
import clarksCover from "../../../assets/partnersCovers/clarks_cover.png";
import skechersCover from "../../../assets/partnersCovers/sketchers_cover.png";
import parapharmTarzaaliCover from "../../../assets/partnersCovers/parapharm_taghzali_cover.png";
import bleuBlancCover from "../../../assets/partnersCovers/bleu_blanc_cover.png";
import electroramaCover from "../../../assets/partnersCovers/electrorama_cover.png";
import orchestraCover from "../../../assets/partnersCovers/orchestra_cover.png";
import electroRoyalCover from "../../../assets/partnersCovers/electroroyale_cover.png";
import insagCover from "../../../assets/partnersCovers/insag_cover.png";
//import dojoCover from "../../../assets/partnersCovers/dojo_cover.png";
import promodCover from "../../../assets/partnersCovers/promod_cover.png";
import airstreetCover from "../../../assets/partnersCovers/airstreet_cover.png";
import familiParcCover from "../../../assets/partnersCovers/famili_parc_cover.png";
import myfitboxCover from "../../../assets/partnersCovers/myfitbox_cover.png";
import akramNourCover from "../../../assets/partnersCovers/akram_nour_cover.png";
import metalSportCover from "../../../assets/partnersCovers/metal_sport_cover.png";
import idealBrandCover from "../../../assets/partnersCovers/ideal_brand_cover.png";
import ironParkCover from "../../../assets/partnersCovers/iron_park_cover.png";
import familyDayCover from "../../../assets/partnersCovers/family_day_cover.png";
import alphaGymCover from "../../../assets/partnersCovers/alpha_gym_cover.png";
//import gsportfond from "../../../assets/partnersCovers/gsportfond.png";
import alcCover from "../../../assets/partnersCovers/alc_cover.png";
import profootPlusCover from "../../../assets/partnersCovers/profoot_plus_cover.png";
import egdCover from "../../../assets/partnersCovers/egd_cover.png";
import pmgCover from "../../../assets/partnersCovers/pmg_cover.png";
import victoryHandbagCover from "../../../assets/partnersCovers/victory_handbag_cover.png";
//import birkenstockCover from "../../../assets/partnersCovers/birkenstock_cover.png";
import dimCover from "../../../assets/partnersCovers/dim_cover.png";
import lacosteCover from "../../../assets/partnersCovers/lacoste_cover.png";
import levisCover from "../../../assets/partnersCovers/levis_cover.png";
import timberlandCover from "../../../assets/partnersCovers/timberland_cover.png";
import tommyHilfigerCover from "../../../assets/partnersCovers/tommy_hilfiger_cover.png";
import thurayaLandCover from "../../../assets/partnersCovers/thuraya_land_cover.png";
import zoneSportCover from "../../../assets/partnersCovers/sport_zone_cover.png";
import pepeJeansCover from "../../../assets/partnersCovers/pepe_jeans_cover.png";
import kiabiCover from "../../../assets/partnersCovers/kiabi_cover.png";
import samsungCover from "../../../assets/partnersCovers/samsung_cover.png";
import eryamCover from "../../../assets/partnersCovers/eryam_cover.png";
import starPeintureCover from "../../../assets/partnersCovers/star_peinture_cover.png";
//import mayferCover from "../../../assets/partnersCovers/mayfer_cover.png";
import maisonDuSavonCover from "../../../assets/partnersCovers/maison_du_savon_cover.png";
import mformatiqueCover from "../../../assets/partnersCovers/mformatique_cover.png";
import courirCover from "../../../assets/partnersCovers/courir_cover.png";
import hisenseCover from "../../../assets/partnersCovers/hisense_cover.png";
import areejCover from "../../../assets/partnersCovers/areej_cover.png";
import areejMarketCover from "../../../assets/partnersCovers/areej_market_cover.png";
import leComptoirDuChocolatCover from "../../../assets/partnersCovers/le_comptoir_du_chocolat_cover.png";
import etamCover from "../../../assets/partnersCovers/etam_cover.png";
import aldoCover from "../../../assets/partnersCovers/aldo_cover.png";
import undizCover from "../../../assets/partnersCovers/undiz_cover.png";
import callItSpringCover from "../../../assets/partnersCovers/call_it_spring_cover.png";
import lumiramaCover from "../../../assets/partnersCovers/lumirama_cover.png";
import bbeCover from "../../../assets/partnersCovers/bbe_cover.png";
import pentiCover from "../../../assets/partnersCovers/penti_cover.png";
import tatouCover from "../../../assets/partnersCovers/tatou_cover.png";
import kotonCover from "../../../assets/partnersCovers/koton_cover.png";
import dsdamatCover from "../../../assets/partnersCovers/dsdamat_cover.png";
import kigiliCover from "../../../assets/partnersCovers/kigili_cover.png";
import maviCover from "../../../assets/partnersCovers/mavi_cover.png";
import pancoCover from "../../../assets/partnersCovers/panco_cover.png";
import suwenCover from "../../../assets/partnersCovers/suwen_cover.png";
import stylkomCover from "../../../assets/partnersCovers/stylkom_cover.png";
import setreCover from "../../../assets/partnersCovers/setre_cover.png";
import ninewestCover from "../../../assets/partnersCovers/nine_west_cover.png";
import oscarCover from "../../../assets/partnersCovers/oscar_cover.png";
import siliceHouseCover from "../../../assets/partnersCovers/silice_house_cover.png";
import athatCover from "../../../assets/partnersCovers/athat_cover.png";
import doodlesCover from "../../../assets/partnersCovers/doodles_cover.png";
//import cosmibelleCover from "../../../assets/partnersCovers/cosmibelle_cover.png";
import gntCover from "../../../assets/partnersCovers/gnt_cover.png";
import lePrintempsCover from "../../../assets/partnersCovers/le_printemps_cover.png";
import esperanzaCover from "../../../assets/partnersCovers/esperanza_cover.png";
import hugeCover from "../../../assets/partnersCovers/huge_cover.png";
import rosalgerinaCover from "../../../assets/partnersCovers/rosalgerina_cover.png";
import maison123Cover from "../../../assets/partnersCovers/maison_123_cover.png";
import dreamHouseCover from "../../../assets/partnersCovers/dream_house_cover.png";
import cacharelCover from "../../../assets/partnersCovers/cacharel_cover.png";
import pribaCover from "../../../assets/partnersCovers/priba_cover.png";
import shapesCover from "../../../assets/partnersCovers/shapes_cover.png";
//import nardiCover from "../../../assets/partnersCovers/nardi_cover.png";
import marjoryCover from "../../../assets/partnersCovers/marjory_cover.png";
import arveaCover from "../../../assets/partnersCovers/arvea_cover.png";
import europaSportCover from "../../../assets/partnersCovers/europa_sport_cover.png";
import benettonCover from "../../../assets/partnersCovers/benetton_cover.png";
import jackJonesCover from "../../../assets/partnersCovers/jack_jones_cover.png";
import megaConfortCover from "../../../assets/partnersCovers/mega_confort_cover.png";
import acaciaHomeDesignCover from "../../../assets/partnersCovers/acacia_home_design_cover.png";
//import usPoloAssnCover from "../../../assets/partnersCovers/us_polo_assn_cover.png";
import gomodeCover from "../../../assets/partnersCovers/gomode_cover.png";
import thomsonCover from "../../../assets/partnersCovers/thomson_cover.jpg";
import coolClubCover from "../../../assets/partnersCovers/cool_club_cover.jpg";
import cityStyleCover from "../../../assets/partnersCovers/city_style_cover.jpg";
import tafsutCover from "../../../assets/partnersCovers/tafsut_cover.png";
import lcWaikikiCover from "../../../assets/partnersCovers/lc_waikiki_cover.png";
import celioCover from "../../../assets/partnersCovers/celio_cover.png";
import mangoCover from "../../../assets/partnersCovers/mango_cover.png";
import barakiMallCover from "../../../assets/partnersCovers/baraki_mall_cover.png";








export const partners = [
  /*{
    id: 0,
    name: "Zara",
    teaser: "Vêtements pour femmes, hommes et enfants",
    teaserEn: "Clothing for women, men and children",
    logo: zaraLogo,
    cover: zaraCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
      "azadea card",
    ],
    wilayas: ["Alger", "Oran"],
    hasWebsite: 0,
  },*/
  {
    id: 1,
    name: "Must",
    teaser: "Distributeur officiel de parfums et cosmétiques",
    teaserEn: "Official distributor of perfumes and cosmetics",
    logo: mustLogo,
    cover: mustCover,
    categories: ["Cosmétique"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Alger", "Oran", "Sétif", "Constantine"],
    hasWebsite: 1,
  },
  /*
  {
    id: 2,
    name: "Pull&Bear",
    teaser:
      "Vêtements pour hommes et femmes avec un design moderne et décontracté",
    teaserEn: "Men's and women's clothing with a modern, casual design",
    logo: pullandbearLogo,
    cover: pullandbearCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
      "azadea card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 3,
    name: "Stradivarius",
    teaser: "Vêtements pour femmes aux tendances émergentes",
    teaserEn: "Emerging trends in women's clothing",
    logo: stradivariusLogo,
    cover: stradivariusCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
      "azadea card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 4,
    name: "Bershka",
    teaser: "Vêtements, chaussures et accessoires pour hommes et femmes",
    teaserEn: "Clothing, shoes and accessories for men and women",
    logo: bershkaLogo,
    cover: bershkaCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
      "azadea card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 5,
    name: "OYSHO",
    teaser: "Lingerie et vêtements de détente",
    teaserEn: "Lingerie and loungewear",
    logo: oyshoLogo,
    cover: oyshoCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
      "azadea card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 6,
    name: "ZARA HOME",
    teaser:
      "Large gamme de produits pour la maison, linge de lit, serviettes de bain, coussins, rideaux, accessoires de décoration et des articles de table",
    teaserEn:
      "Wide range of products for the house, bed linen, towels, cushions, curtains, decorative accessories and tableware",
    logo: zaraHomeLogo,
    cover: zaraHomeCover,
    categories: ["Déco"],
    includedGiftCardTypes: ["limitless card", "lady card", "azadea card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  */
  {
    id: 95,
    name: "KIABI",
    teaser: "Prêt à porter hommes, femmes et enfants",
    teaserEn: "Ready-to-wear for men, women and kids",
    logo: kiabiLogo,
    cover: kiabiCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger","Constantine", "Oran", "Annaba","Sétif"],
    hasWebsite: 0,
  },
  {
    id: 154,
    name: "MANGO",
    teaser: "Marque espagnole de prêt-à-porter pour femme",
    teaserEn: "Spanish women's ready-to-wear brand.",
    logo: mangoLogo,
    cover: mangoCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 141,
    name: "Europa Sport",
    teaser: "Réseau de prêt à porter hommes, femmes et enfants, sportswear",
    teaserEn: "Ready-to-wear clothing network for men, women and children, sportswear",
    logo: europaSportLogo,
    cover: europaSportCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Sétif", "Annaba","Constantine"],
    hasWebsite: 1,
  },
  {
    id: 152,
    name: "LC Waikiki",
    teaser: "LC Waikiki disponible uniquement à Draria et Constantine",
    teaserEn: "LC Waikiki available only in Draria and Constantine",
    logo: lcWaikikiLogo,
    cover: lcWaikikiCover,
    categories: ["Prêt-à-porter","Enfant"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Constantine"],
    hasWebsite: 0,
  },
  {
    id: 153,
    name: "Celio",
    teaser: "Marque française de prêt-à-porter",
    teaserEn: "French ready-to-wear brand",
    logo: celioLogo,
    cover: celioCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Constantine","Annaba","Sétif"],
    hasWebsite: 0,
  },
  {
    id: 142,
    name: "Benetton",
    teaser: "Marque italienne de vêtements de mode pour enfants",
    teaserEn: "Italian children's fashion brand",
    logo: benettonLogo,
    cover: benettonCover,
    categories: ["Prêt-à-porter","Enfant"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Sétif", "Oran"],
    hasWebsite: 0,
  },
  {
    id: 147,
    name: "GoMode",
    teaser: "Site de vente de plusieurs marques emblématiques qui allient style, qualité et confort",
    teaserEn: "A sales website featuring multiple iconic brands that combine style, quality, and comfort.",
    logo: gomodeLogo,
    cover: gomodeCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: [],
    hasWebsite: 1,
  },
  {
    id: 143,
    name: "JACK & JONES",
    teaser: "Vêtements de mode pour toute la famille",
    teaserEn: "Fashion clothing for the whole family",
    logo: jackJonesLogo,
    cover: jackJonesCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Sétif", "Alger"],
    hasWebsite: 0,
  },
  {
    id: 134,
    name: "Maison 123",
    teaser:
      "Vêtements pour femmes : robes, chemises, mailles, manteaux, tailleurs et accessoires",
    teaserEn:
      "Womenswear: dresses, shirts, knitwear, coats, suits and accessories",
    logo: maison123Logo,
    cover: maison123Cover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Oran"],
    hasWebsite: 0,
  },
  {
    id: 135,
    name: "RosAlgerina 123",
    teaser: "Marque de lingerie algérienne",
    teaserEn: "Algerian lingerie brand",
    logo: rosalgerinaLogo,
    cover: rosalgerinaCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: [],
    hasWebsite: 1,
  },
  {
    id: 38,
    name: "Advanced Office",
    teaser:
      "Fourniture de bureau, Articles scolaires, Matériel Informatique, et bien plus",
    teaserEn:
      "Children's clothing from 0 to 15 years based on Italian style at the best price",
    logo: advancedOfficeLogo,
    cover: advancedOfficeCover,
    categories: ["Electronique"],
    includedGiftCardTypes: ["limitless card", "bts card"],
    wilayas: ["Alger", "Ouargla", "El Oued"],
    hasWebsite: 1,
  },
  {
    id: 155,
    name: "Baraki Mall",
    teaser: "Centre commercial Baraki Mall",
    teaserEn: "Baraki Mall Shopping Center",
    logo: barakiMallLogo,
    cover: barakiMallCover,
    categories: [
      "Prêt-à-porter",
      "Electroménager",
      "Cosmétique",
      "Enfant",
      "Hypermarchés",
    ],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 130,
    name: "Le Printemps",
    teaser: "Déco, vaisselle, cosmétique et textile",
    teaserEn: "Decoration, tableware, cosmetics and textiles",
    logo: lePrintempsLogo,
    cover: lePrintempsCover,
    categories: [
      "Prêt-à-porter",
      "Déco",
      "Electroménager",
      "Cosmétique",
      "Enfant",
    ],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Bouira", "Blida"],
    hasWebsite: 0,
  },
  {
    id: 151,
    name: "Tafsut",
    teaser: "Vêtements , chaussures , jouets et accessoires pour enfants ( de 0 à 16ans )",
    teaserEn: "Clothing, shoes, toys, and accessories for children (from 0 to 16 years old)",
    logo: tafsutLogo,
    cover: tafsutCover,
    categories: [
      "Prêt-à-porter",
      "Cosmétique",
      "Enfant",
    ],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Tizi Ouzou", "Bouira", "Boumerdes"],
    hasWebsite: 0,
  },
  {
    id: 107,
    name: "Etam",
    teaser: "Large sélection de vêtements d'intérieurs et de maillots de bain",
    teaserEn: "Wide selection of loungewear and swimwear",
    logo: etamLogo,
    cover: etamCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Oran"],
    hasWebsite: 0,
  },
  {
    id: 108,
    name: "Undiz",
    teaser: "Sous-vêtements pour hommes et femmes",
    teaserEn: "Underwear for men and women",
    logo: undizLogo,
    cover: undizCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 109,
    name: "ALDO",
    teaser: "Chaussures et accessoires",
    teaserEn: "Shoes and accessories",
    logo: aldoLogo,
    cover: aldoCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Oran"],
    hasWebsite: 0,
  },
  {
    id: 110,
    name: "CALL IT SPRING",
    teaser:
      "Sandales véganes, bottes, chaussures, sac a main pour hommes et femmes",
    teaserEn: "Vegan sandals, boots, shoes, handbags for men and women",
    logo: callItSpringLogo,
    cover: callItSpringCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 113,
    name: "Penti",
    teaser: "Large sélection de vêtements d'intérieurs et de maillots de bain",
    teaserEn: "Wide selection of loungewear and swimwear",
    logo: pentiLogo,
    cover: pentiCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Sétif", "Oran"],
    hasWebsite: 0,
  },
  {
    id: 114,
    name: "Tatou",
    teaser: "Une large collection de vêtements pour femmes",
    teaserEn: "A wide collection of women's clothing",
    logo: tatouLogo,
    cover: tatouCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 115,
    name: "Koton",
    teaser: "Fast Fashion pour hommes, femmes et enfants",
    teaserEn: "Fast Fashion for men, women and kids",
    logo: kotonLogo,
    cover: kotonCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Oran"],
    hasWebsite: 0,
  },
  {
    id: 116,
    name: "D'S damat",
    teaser: "Classique pour hommes",
    teaserEn: "Classic menswear",
    logo: dsdamatLogo,
    cover: dsdamatCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "ramadhaniya card",
    ],
    wilayas: ["Oran"],
    hasWebsite: 0,
  },
  {
    id: 117,
    name: "Kigili",
    teaser: "Classique pour hommes",
    teaserEn: "Classic menswear",
    logo: kigiliLogo,
    cover: kigiliCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "ramadhaniya card",
    ],
    wilayas: ["Oran"],
    hasWebsite: 0,
  },
  {
    id: 118,
    name: "Mavi",
    teaser: "Large collection de jeans",
    teaserEn: "A wide range of jeans",
    logo: maviLogo,
    cover: maviCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Oran"],
    hasWebsite: 0,
  },
  {
    id: 119,
    name: "PANCO",
    teaser: "Vêtements pour enfants",
    teaserEn: "Kids' clothing",
    logo: pancoLogo,
    cover: pancoCover,
    categories: ["Enfant"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "bts card",
      "ramadhaniya card",
    ],
    wilayas: ["Oran"],
    hasWebsite: 0,
  },
  {
    id: 120,
    name: "SUWEN",
    teaser: "Vêtements d’intérieur pour femmes",
    teaserEn: "Women's loungewear",
    logo: suwenLogo,
    cover: suwenCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Oran"],
    hasWebsite: 0,
  },
  {
    id: 121,
    name: "STYLKOM",
    teaser: "Différentes marques de textiles pour femmes",
    teaserEn: "Different brands of women's textiles",
    logo: stylkomLogo,
    cover: stylkomCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Oran"],
    hasWebsite: 0,
  },
  {
    id: 150,
    name: "City Style",
    teaser: "Prêt à porter",
    teaserEn: "Ready-to-wear",
    logo: cityStyleLogo,
    cover: cityStyleCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Oran"],
    hasWebsite: 0,
  },
  {
    id: 122,
    name: "SETRE",
    teaser: "Classique pour Femmes",
    teaserEn: "Women's classic clothing",
    logo: setreLogo,
    cover: setreCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Oran"],
    hasWebsite: 0,
  },
  {
    id: 123,
    name: "NINE WEST",
    teaser: "Sacs et chaussures de luxe",
    teaserEn: "Luxury bags and shoes",
    logo: ninewestLogo,
    cover: ninewestCover,
    categories: ["Prêt-à-porter", "Accessoires"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Oran"],
    hasWebsite: 0,
  },
  {
    id: 7,
    name: "Guess",
    teaser: "La mode pour hommes, femmes, enfants et accessoires",
    teaserEn: "Fashion for men, women, children and accessories",
    logo: guessLogo,
    cover: guessCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Oran"],
    hasWebsite: 0,
  },
  /*
  {
    id: 8,
    name: "Massimo Dutti",
    teaser: "Marque espagnole de vêtements",
    teaserEn: "Spanish clothing brand",
    logo: massimoduttiLogo,
    cover: massimoduttiCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
      "azadea card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  */
  {
    id: 136,
    name: "Cacharel",
    teaser: "Prêt à porter homme",
    teaserEn: "Men's ready-to-wear",
    logo: cacharelLogo,
    cover: cacharelCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 112,
    name: "BBE",
    teaser:
      "Vente de produits d'électroménager multimarques : Samsung, Raylan, Seb, Bosch, Moulinex, Stellar, Whirlpool, Rosières...",
    teaserEn:
      "Multi-brand household appliances: Samsung, Raylan, Seb, Bosch, Moulinex, Stellar, Whirlpool, Rosières...",
    logo: bbeLogo,
    cover: bbeCover,
    categories: ["Electroménager"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 148,
    name: "Thomson Sacomi",
    teaser:
      "Marque française spécialisée dans l'électroménager et l'électronique",
    teaserEn:
      "French brand specialized in home appliances and electronics",
    logo: thomsonLogo,
    cover: thomsonCover,
    categories: ["Electroménager","Electronique"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Alger","M'Sila","Boumerdes","Bouira","Sidi Bel Abbes","Tiaret","Mascara","Batna","Blida","Annaba","Bordj-Bou-Arreridj","Chlef","Oran"],
    hasWebsite: 0,
  },
  {
    id: 102,
    name: "Hisense",
    teaser: "Fabricant mondial de téléviseurs et électroménagers",
    teaserEn: "Global manufacturer of televisions and household appliances",
    logo: hisenseLogo,
    cover: hisenseCover,
    categories: ["Electroménager"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 96,
    name: "Samsung",
    teaser: "Produits d'électroménager et solutions électroniques",
    teaserEn: "Household appliances and electronic solutions",
    logo: samsungLogo,
    cover: samsungCover,
    categories: ["Electroménager"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Alger", "Sétif", "Annaba","Tizi Ouzou"],
    hasWebsite: 0,
  },
  {
    id: 84,
    name: "PMG",
    teaser: "The House of Brands",
    teaserEn: "The House of Brands",
    logo: pmgLogo,
    cover: pmgCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Oran", "Sétif"],
    hasWebsite: 1,
  },

  {
    id: 104,
    name: "AREEJ",
    teaser: "Large choix de produits pour toute la famille",
    teaserEn: "A wide range of products for the whole family",
    logo: areejLogo,
    cover: areejCover,
    categories: ["Prêt-à-porter", "Déco", "Cosmétique", "Enfant"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
      "bts card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 111,
    name: "Lumirama",
    teaser:
      "Une chaine de magasins proposant une variété de produits d'éclairages",
    teaserEn: "A range of shops offering a wide variety of lighting products",
    logo: lumiramaLogo,
    cover: lumiramaCover,
    categories: ["Déco"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Sétif", "Oran", "Constantine", "Tlemcen"],
    hasWebsite: 0,
  },

  {
    id: 94,
    name: "VICTORY HANDBAG",
    teaser: "Collection de sacs à main",
    teaserEn: "Handbags collection",
    logo: victoryHandbagLogo,
    cover: victoryHandbagCover,
    categories: ["Prêt-à-porter", "Accessoires"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: [],
    hasWebsite: 1,
  },

  {
    id: 93,
    name: "Pepe Jeans",
    teaser: "Mode anglaise pour femme, homme et enfant",
    teaserEn: "British fashion for women, men and children",
    logo: pepeJeansLogo,
    cover: pepeJeansCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Oran"],
    hasWebsite: 0,
  },
  /*
  {
    id: 85,
    name: "BIRKENSTOCK",
    teaser: "Sandales pour hommes et femmes",
    teaserEn: "Sandals for men and women",
    logo: birkenstockLogo,
    cover: birkenstockCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  */
  {
    id: 86,
    name: "DIM",
    teaser: "Sous-vêtements pour hommes et femmes",
    teaserEn: "Undergarments for men and women",
    logo: dimLogo,
    cover: dimCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Oran"],
    hasWebsite: 0,
  },
  {
    id: 87,
    name: "LACOSTE",
    teaser: "Marque de sportswear pour hommes et femmes",
    teaserEn: "Sportswear brand for men and women",
    logo: lacosteLogo,
    cover: lacosteCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Oran"],
    hasWebsite: 0,
  },
  {
    id: 88,
    name: "LEVI'S",
    teaser: "Marque de Jean pour hommes et femmes",
    teaserEn: "Jeans for men and women",
    logo: levisLogo,
    cover: levisCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger","Oran"],
    hasWebsite: 0,
  },
  {
    id: 89,
    name: "TIMBERLAND",
    teaser: "Marque de vêtements et chaussures pour hommes, femmes et enfants",
    teaserEn: "Clothing and footwear for men, women and kids",
    logo: timberlandLogo,
    cover: timberlandCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Oran"],
    hasWebsite: 0,
  },
  {
    id: 90,
    name: "TOMMY HILFIGER",
    teaser: "Vêtements de mode pour hommes, femmes et enfants",
    teaserEn: "Fashion clothing for men, women and children",
    logo: tommyHilfigerLogo,
    cover: tommyHilfigerCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 91,
    name: "Zone Sport",
    teaser: "Chaine de magasins sportswear multi-marques",
    teaserEn: "Multi-brand sportswear chain",
    logo: zoneSportLogo,
    cover: zoneSportCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 9,
    name: "Promod",
    teaser: "Marque française de prêt-à-porter féminin",
    teaserEn: "French ready-to-wear women's brand",
    logo: promodLogo,
    cover: promodCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 1,
  },
  {
    id: 10,
    name: "EyeWear Algérie",
    teaser:
      "Chaîne de magasins de lunettes optique et solaire en algerie, spécialisée dans le haut de gamme",
    teaserEn:
      "Optical and sunglasses chain of stores in Algeria, specialized in the high-end",
    logo: eyewearLogo,
    cover: eyewearCover,
    categories: ["Accessoires"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Alger", "Oran", "Sétif", "Batna"],
    hasWebsite: 0,
  },
  {
    id: 131,
    name: "ESPERANZA",
    teaser:
      "Grande surface de déco, vaisselle, petit électroménager et articles ménagers",
    teaserEn:
      "Large store for decor, tableware, small appliances, and household items",
    logo: esperanzaLogo,
    cover: esperanzaCover,
    categories: ["Déco", "Electroménager"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Béjaïa"],
    hasWebsite: 0,
  },
  {
    id: 12,
    name: "Electro Royale Plus",
    teaser:
      "Produits électroménager des marques : Condor, Nardi, Hisense, Cristor, Beko, Brandt, Géant, Iris, Raylan et Samsung",
    teaserEn:
      "Household appliance brands: Condor, Nardi, Hisense, Cristor, Beko, Brandt, Géant, Iris, Raylan and Samsung",
    logo: electRoroyalLogo,
    cover: electroRoyalCover,
    categories: ["Electroménager"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Alger", "Blida", "Tipaza", "Oran", "Boumerdes", "Bordj Bou Arreridj"],
    hasWebsite: 0,
  },
  {
    id: 13,
    name: "Watch Center",
    teaser:
      "Watch Center en Algérie. En plus des marques : Edifice, Sheen, G-Shock, Baby-G, Pro Trek",
    teaserEn:
      "Watch Center in Algeria. In addition to the brands: Edifice, Sheen, G-Shock, Baby-G, Pro Trek",
    logo: casioLogo,
    cover: casioCover,
    categories: ["Accessoires", "Montres"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Alger", "Sétif"],
    hasWebsite: 1,
  },
  {
    id: 14,
    name: "Zippy",
    teaser:
      "Vêtements pour enfants du nouveau-né jusqu'à 14 ans, puériculture, meubles et jouets",
    teaserEn:
      "Children's clothing from newborn to 14 years old, nursery, furniture and toys",
    logo: zippyLogo,
    cover: zippyCover,
    categories: ["Enfant"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "ramadhaniya card",
      "bts card",
    ],
    wilayas: ["Alger", "Oran"],
    hasWebsite: 1,
  },
  {
    id: 149,
    name: "Cool Club",
    teaser:
      "Vêtements pour enfants",
    teaserEn:
      "Children's clothing",
    logo: coolClubLogo,
    cover: coolClubCover,
    categories: ["Enfant"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "ramadhaniya card",
      "bts card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 15,
    name: "Stepmode",
    teaser: "Chaussures de grandes marques internationales",
    teaserEn: "Shoes from major international brands",
    logo: stepmodeLogo,
    cover: stepmodeCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Blida", "Laghouat", "Médea", "Tizi Ouzou"],
    hasWebsite: 1,
  },
  {
    id: 16,
    name: "SKECHERS",
    teaser:
      "Marque de chaussures renommée pour ses modèles confortables et tendance, adaptés à tous les âges",
    teaserEn:
      "Footwear brand known for its comfortable, trendy designs, suitable for all ages",
    logo: skechersLogo,
    cover: skechersCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Oran", "Sétif", "Blida", "Annaba", "Constantine","Béjaïa","Boumerdes"],
    hasWebsite: 0,
  },
  {
    id: 103,
    name: "Courir",
    teaser: "Chaussures, vêtements et accessoires de sportswear",
    teaserEn: "Footwear, sportswear clothing and accessories",
    logo: courirLogo,
    cover: courirCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Oran","Alger"],
    hasWebsite: 0,
  },

  {
    id: 17,
    name: "Footland Store",
    teaser:
      "Magasin proposant différentes marques de chaussures pour hommes, femmes et enfants",
    teaserEn:
      "Brand offering various brands of shoes for men, women and children",
    logo: footlandLogo,
    cover: footlandCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: [
      "Alger",
      "Sétif",
      "Annaba",
      "Tlemcen",
      "Djelfa",
      "Batna",
      "Ouargla",
    ],
    hasWebsite: 0,
  },
  {
    id: 18,
    name: "Clarks",
    teaser: "Marque Anglaise de chaussures pour hommes et femmes",
    teaserEn: "English footwear brand for men and women",
    logo: clarksLogo,
    cover: clarksCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 140,
    name: "Marjory - Seiko",
    teaser: "Marque japonaise pour montres Homme et Femme",
    teaserEn: "Japanese brand for men's and women's watches",
    logo: marjoryLogo,
    cover: marjoryCover,
    categories: ["Montres"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Alger", "Oran", "Constantine"],
    hasWebsite: 1,
  },

  {
    id: 76,
    name: "Ideal Brand",
    teaser:
      "Distributeur de marques de chaussures, habillement et accessoires de renommée mondiale",
    teaserEn:
      "Distributor of internationally known brands of footwear, clothing and accessories.",
    logo: idealBrandLogo,
    cover: idealBrandCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },

  {
    id: 19,
    name: "AIR STREET",
    teaser: "Dsitributeur officiel des marques Peak, Rider et Cartago",
    teaserEn: "Official distributor of Peak, Rider and Cartago brands",
    logo: airstreetLogo,
    cover: airstreetCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Blida", "Annaba"],
    hasWebsite: 1,
  },
  {
    id: 20,
    name: "XINT",
    teaser: "Prêt-à-porter pour femmes",
    teaserEn: "Ready to wear for women",
    logo: xintLogo,
    cover: xintCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 1,
  },
  {
    id: 21,
    name: "JAKAMEN",
    teaser: "Prêt-à-porter et sur mesure pour homme",
    teaserEn: "Ready to wear and made-to-measure for men",
    logo: jakamenLogo,
    cover: jakamenCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 1,
  },
  /*
  {
    id: 146,
    name: "U.S POLO ASSN",
    teaser: "Une vaste sélection de vêtements décontractés de style classique de haute qualité",
    teaserEn: "A wide selection of high-quality, classic-style casual clothing",
    logo: usPoloAssnLogo,
    cover: usPoloAssnCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger","Oran","Sétif"],
    hasWebsite: 0,
  },
  */
  {
    id: 22,
    name: "Insag Business School",
    teaser: "Institut supérieur de formations",
    teaserEn: "Higher Institute of Training",
    logo: insagLogo,
    cover: insagCover,
    categories: ["Formations"],
    includedGiftCardTypes: ["limitless card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 81,
    name: "ALC - Algerian Learning Centers",
    teaser: "École de langues",
    teaserEn: "Language school",
    logo: alcLogo,
    cover: alcCover,
    categories: ["Formations"],
    includedGiftCardTypes: ["limitless card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 23,
    name: "Djaz Market",
    teaser: "Boutique en ligne : Informatique et Technologies",
    teaserEn: "Computers and Technologies",
    logo: djazMarketLogo,
    cover: djazMarketCover,
    categories: ["Electronique"],
    includedGiftCardTypes: ["limitless card", "lady card", "bts card"],
    wilayas: [],
    hasWebsite: 1,
  },
  {
    id: 24,
    name: "iConcept",
    teaser: "Boutique officielle de produits Apple",
    teaserEn: "Official Apple products store",
    logo: iconceptLogo,
    cover: iconceptCover,
    categories: ["Electronique"],
    includedGiftCardTypes: ["limitless card", "lady card", "bts card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 124,
    name: "AGM",
    teaser: "Vente d'équipements électroniques et de téléphones",
    teaserEn: "Electronic equipment and telephony retailer",
    logo: gntLogo,
    cover: gntCover,
    categories: ["Electronique"],
    includedGiftCardTypes: ["limitless card", "lady card", "bts card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 101,
    name: "MFormatik",
    teaser:
      "Prestataire proposant des services informatiques dans tous types de domaines",
    teaserEn: "Provider of IT services in all fields",
    logo: mformatiqueLogo,
    cover: mformatiqueCover,
    categories: ["Electronique"],
    includedGiftCardTypes: ["limitless card", "lady card", "bts card"],
    wilayas: ["M'Sila"],
    hasWebsite: 0,
  },
  /*
  {
    id: 25,
    name: "Sunglass Hut",
    teaser: "Lunettes de soleil et d'accessoires pour lunettes de soleil",
    teaserEn: "Sunglasses and sunglasses accessories",
    logo: sunglassHutLogo,
    cover: sunglassHutCover,
    categories: ["Accessoires"],
    includedGiftCardTypes: ["limitless card", "lady card", "azadea card"],
    wilayas: ["Alger", "Oran", "Sétif"],
    hasWebsite: 0,
  },
  */
  {
    id: 26,
    name: "Casa Shopping",
    teaser: "Supermarché",
    teaserEn: "Supermarket",
    logo: casaShoppingLogo,
    cover: casaCover,
    categories: ["Hypermarchés"],
    includedGiftCardTypes: ["limitless card", "ramadhaniya card", "bts card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 105,
    name: "AREEJ MARKET",
    teaser: "Hypermarché",
    teaserEn: "Hypermarket",
    logo: areejMarketLogo,
    cover: areejMarketCover,
    categories: ["Hypermarchés"],
    includedGiftCardTypes: ["limitless card", "ramadhaniya card", "bts card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 27,
    name: "Le Grand Maghreb",
    teaser: "Supermarché",
    teaserEn: "Supermarket",
    logo: leGrandMagrebLogo,
    cover: leGrandMagrebCover,
    categories: ["Hypermarchés"],
    includedGiftCardTypes: ["limitless card", "ramadhaniya card", "bts card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 28,
    name: "Lider Market",
    teaser: "Supermarché",
    teaserEn: "Supermarket",
    logo: liderMarketLogo,
    cover: liderMarketCover,
    categories: ["Hypermarchés"],
    includedGiftCardTypes: ["limitless card", "ramadhaniya card", "bts card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 29,
    name: "Hugo Boss",
    teaser: "Prêt-à-porter",
    teaserEn: "Ready to wear",
    logo: hugoBossLogo,
    cover: HugobossCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 126,
    name: "Silice House",
    teaser: "Vaisselle, électroménager et aménagement d'intérieur",
    teaserEn: "Kitchenware, household appliances and interior design",
    logo: siliceHouseLogo,
    cover: siliceHouseCover,
    categories: ["Electroménager", "Déco"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Alger"],
    hasWebsite: 1,
  },
  {
    id: 144,
    name: "MEGA CONFORT",
    teaser: "Magasin de meubles et d'articles de décoration, se caractérisant par un style éclectique",
    teaserEn: "Eclectic furniture and home furnishings store",
    logo: megaConfortLogo,
    cover: megaConfortCover,
    categories: ["Déco"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 145,
    name: "ACACIA Home & Design",
    teaser: "Magasin d'ameublement et de décoration avec une ambiance méditerranéenne",
    teaserEn: "Home furnishings and decoration store with Mediterranean flair",
    logo: acaciaHomeDesignLogo,
    cover: acaciaHomeDesignCover,
    categories: ["Déco"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 30,
    name: "Maizonia",
    teaser:
      "Boutique d’électroménager, son, image, déco, vaisselle et ustensile de cuisine",
    teaserEn:
      "Store of household appliances, audio, television, decoration, dishes and kitchen utensils",
    logo: maisoniaLogo,
    cover: maisoniaCover,
    categories: ["Electroménager"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Oran"],
    hasWebsite: 1,
  },

  {
    id: 31,
    name: "Electrorama",
    teaser: "Vente de produits d'électroménager multimarques",
    teaserEn: "Multi-brand household appliance sales",
    logo: electroramaLogo,
    cover: electroramaCover,
    categories: ["Electroménager"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Oran"],
    hasWebsite: 0,
  },

  {
    id: 75,
    name: "Akram Nor",
    teaser: "Chaine de magasins d’électroménager",
    teaserEn: "Household appliance chain",
    logo: akramNourLogo,
    cover: akramNourCover,
    categories: ["Electroménager"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: [
      "Bordj Bou Arreridj",
      "Béjaïa",
      "Bouira",
      "Djelfa",
      "Oran",
      "Touggourt",
    ],
    hasWebsite: 0,
  },
  {
    id: 83,
    name: "Electro Global Distribution",
    teaser:
      "Vente de produits électroménager de la marque espagnole TAURUS, Seb, LG et UFESA",
    teaserEn:
      "Sale of household appliances from Spanish brands TAURUS, Seb, LG and UFESA",
    logo: egdLogo,
    cover: egdCover,
    categories: ["Electroménager"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },

  {
    id: 32,
    name: "Piové",
    teaser: "Produits de beauté et de cosmétique",
    teaserEn: "Beauty and cosmetic products",
    logo: pioveLogo,
    cover: pioveCover,
    categories: ["Cosmétique"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Alger", "Blida", "Sétif", "Constantine", "Tizi ouzou", "Oran"],
    hasWebsite: 0,
  },
  {
    id: 33,
    name: "Famili Shop",
    teaser: "Hypermarché",
    teaserEn: "Hypermarket",
    logo: familiShopLogo,
    cover: familiShopCover,
    categories: ["Hypermarchés"],
    includedGiftCardTypes: ["limitless card", "ramadhaniya card", "bts card"],
    wilayas: ["Blida", "Tissemsilt"],
    hasWebsite: 0,
  },
  {
    id: 72,
    name: "Famili Parc",
    teaser: "Parc d’attractions et de loisirs",
    teaserEn: "Amusement and Leisure Park",
    logo: familiParcLogo,
    cover: familiParcCover,
    categories: ["Loisir"],
    includedGiftCardTypes: ["limitless card"],
    wilayas: ["Tissemsilt"],
    hasWebsite: 0,
  },
  {
    id: 106,
    name: "Le Comptoir du Chocolat",
    teaser: "Chocolaterie artisanale",
    teaserEn: "Artisanal chocolate shop",
    logo: leComptoirDuChocolatLogo,
    cover: leComptoirDuChocolatCover,
    categories: ["Autre"],
    includedGiftCardTypes: ["limitless card"],
    wilayas: ["Alger"],
    hasWebsite: 1,
  },
  {
    id: 77,
    name: "Family Day",
    teaser: "Supermarché",
    teaserEn: "Supermarket",
    logo: familyDayLogo,
    cover: familyDayCover,
    categories: ["Hypermarchés"],
    includedGiftCardTypes: ["limitless card", "ramadhaniya card", "bts card"],
    wilayas: ["Blida"],
    hasWebsite: 0,
  },
  /*
  {
    id: 97,
    name: "Mayfer",
    teaser: "Marque de prêt à porter Franco-Algérienne réalisant du sur mesure",
    teaserEn: "French-Algerian ready-to-wear brand, custom-made to order",
    logo: mayferLogo,
    cover: mayferCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  */
  {
    id: 34,
    name: "Le Nomade",
    teaser:
      "Représentant officiel des marques : BOSS, HUGO, ZEGNA, ARMANI EXCHANGE, SALVATORE FERRAGAMO, PAUL & SHARK, BALLY, REPLAY , TOD'S HOGAN, FAY, DIESEL",
    teaserEn:
      "Official representative of the brands: BOSS, HUGO, ZEGNA, ARMANI EXCHANGE, SALVATORE FERRAGAMO, PAUL & SHARK, BALLY, REPLAY , TOD'S HOGAN, FAY, DIESEL",
    logo: leNomadeLogo,
    cover: leNomadeCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Oran", "Sétif"],
    hasWebsite: 0,
  },
  {
    id: 35,
    name: "Sugar",
    teaser: "Sacs à mains, lunettes de soleil, petits accessoires, etc",
    teaserEn: "Handbags, sunglasses, small accessories, etc.",
    logo: sugarLogo,
    cover: sugarCover,
    categories: ["Accessoires"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Alger","Tlemcen","Constantine"],
    hasWebsite: 0,
  },
  {
    id: 125,
    name: "ATH'AT",
    teaser: "Magasin d'ameublement et de décoration",
    teaserEn: "Furniture and decoration store",
    logo: athatLogo,
    cover: athatCover,
    categories: ["Déco"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 36,
    name: "Home&You",
    teaser:
      "Produits de décoration intérieure et de textiles et articles d'ameublement",
    teaserEn: "Interior decoration products and textiles and furnishings",
    logo: homeYouLogo,
    cover: homeYouCover,
    categories: ["Déco"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Oran","Alger"],
    hasWebsite: 1,
  },
  {
    id: 127,
    name: "Doodles",
    teaser:
      "Articles divers (Poufs à Microbille, coussin de voyage, coussin d’allègement...)",
    teaserEn:
      "Various items (Microbead cushions, travel cushions, weight-lifting cushions...)",
    logo: doodlesLogo,
    cover: doodlesCover,
    categories: ["Accessoires", "Déco"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 37,
    name: "OVS Kids",
    teaser:
      "Vêtements pour enfants de 0 à 15 ans basés sur le style italien au meilleur prix",
    teaserEn:
      "Children's clothing from 0 to 15 years based on Italian style at the best price",
    logo: ovsKidsLogo,
    cover: ovsKidsCover,
    categories: ["Enfant"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "ramadhaniya card",
      "bts card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 1,
  },
  {
    id: 39,
    name: "Unibrandz",
    teaser:
      "Vêtements et Accessoires de marques Adidas, Nike, Puma, Reebook et plus encore",
    teaserEn:
      "Clothing and Accessories from Adidas, Nike, Puma, Reebook and more",
    logo: unibrandzLogo,
    cover: unibrandsCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Batna", "Blida"],
    hasWebsite: 1,
  },
  {
    id: 40,
    name: "UNIHOOPS",
    teaser: "Magasin de Sneakers et Sportswear Basketball",
    teaserEn: "Sneakers and Sportswear Basketball Store",
    logo: unihoopsLogo,
    cover: unihoopsCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 82,
    name: "Profoot Plus",
    teaser:
      "Magasins de Vêtements, Chaussures et équipements de Sports Multimarques",
    teaserEn: "Multi-brand clothing, footwear and sports equipment stores",
    logo: profootPlusLogo,
    cover: profootPlusCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Sétif"],
    hasWebsite: 0,
  },
  /*
  {
    id: 41,
    name: "NexTimes",
    teaser:
      "Représentant officiel des marques de montre FOSSIL, SKAGEN, DIESEL, DKNY, ARMANI EXCHANGE, TOMMY HILFIGER, Michael Kors et Emporio Armani",
    teaserEn:
      "Official representative of the watch brands FOSSIL, SKAGEN, DIESEL, DKNY, ARMANI EXCHANGE, TOMMY HILFIGER, Michael Kors and Emporio Armani",
    logo: nexttimesLogo,
    cover: nextTimesCover,
    categories: ["Accessoires", "Montres"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Alger"],
    hasWebsite: 1,
  },
  */
  {
    id: 42,
    name: "Flowers By SAIDA",
    teaser:
      "Fleurs et plantes naturelles pour embellir vos espaces et évènements",
    teaserEn: "Flowers and natural plants to embellish your spaces and events",
    logo: flowerBySaidaLogo,
    cover: flowersBySaidaCover,
    categories: ["Déco"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 43,
    name: "Camper",
    teaser: "Marque espagnole de chaussures",
    teaserEn: "Spanish footwear brand",
    logo: camperLogo,
    cover: camperCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 44,
    name: "Manzil by Farimer",
    teaser: "Produits de qualité et design pour la cuisine et la maison",
    teaserEn: "Quality and design products for the kitchen and home",
    logo: manzilLogo,
    cover: manzilCover,
    categories: ["Déco"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Alger", "Oran"],
    hasWebsite: 1,
  },
  {
    id: 45,
    name: "Basics Cosmetics",
    teaser: "Soins cosmétiques et beauté",
    teaserEn: "Cosmetic and beauty care",
    logo: basicsCosmeticLogo,
    cover: basicsCosmeticCover,
    categories: ["Cosmétique"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  /*{
    id: 128,
    name: "Cosmibelle",
    teaser: "Produits de beauté et cosmétiques",
    teaserEn: "Beauty products and cosmetics",
    logo: cosmibelleLogo,
    cover: cosmibelleCover,
    categories: ["Cosmétique"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },*/
  {
    id: 128,
    name: "ARVEA",
    teaser: "Marque internationale de beauté et de bien être",
    teaserEn: "International brand of beauty and well-being",
    logo: arveaLogo,
    cover: arveaCover,
    categories: ["Cosmétique"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: [],
    hasWebsite: 1,
  },
  {
    id: 46,
    name: "Nid’Ange Algérie",
    teaser: "Habillement, Puériculture, Jouets, Cosmétique",
    teaserEn: "Clothing, Childcare, Toys, Cosmetics",
    logo: nidangeLogo,
    cover: nidangesCover,
    categories: ["Enfant"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },

  {
    id: 47,
    name: "Mayoral",
    teaser:
      "Vêtements, chaussures et accessoires pour enfants de marque espagnole de 0 - 18ans",
    teaserEn:
      "Spanish children's clothing, shoes and accessories from 0 - 18 years old",
    logo: mayoralLogo,
    cover: mayoralCover,
    categories: ["Enfant"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "ramadhaniya card",
      "bts card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 1,
  },
  {
    id: 48,
    name: "ORCHESTRA",
    teaser: "Vêtements pour enfants et bébés de 0 à 14ans.",
    teaserEn: "Clothes for children and babies from 0 to 14 years.",
    logo: orchestraLogo,
    cover: orchestraCover,
    categories: ["Enfant"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "ramadhaniya card",
      "bts card",
    ],
    wilayas: ["Alger", "Oran", "Sétif","Tizi Ouzou"],
    hasWebsite: 0,
  },
  {
    id: 49,
    name: "GYPlus",
    teaser: "Petit électroménager de la marque espagnole TAURUS",
    teaserEn: "Small household appliances of the Spanish brand TAURUS",
    logo: gyplusLogo,
    cover: gyplusCover,
    categories: ["Electroménager"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Constantine","Annaba","Alger","Jijel"],
    hasWebsite: 1,
  },
  {
    id: 133,
    name: "DREAM HOUSE SETIF",
    teaser: "Vaisselle, électroménager et aménagement d'intérieur",
    teaserEn: "Tableware, household appliances and home furnishings",
    logo: dreamHouseLogo,
    cover: dreamHouseCover,
    categories: ["Electroménager"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Sétif"],
    hasWebsite: 0,
  },
  {
    id: 50,
    name: "Ochakra",
    teaser: "Institut de SPA et de Bien-être",
    teaserEn: "SPA and Wellness Institute",
    logo: oshakraLogo,
    cover: oshakraCover,
    categories: ["SPA Bien-être"],
    includedGiftCardTypes: ["limitless card", "move&relax card", "lady card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 51,
    name: "FootZone",
    teaser:
      "Distribution exclusive de marques de chaussures, habillement et accessoires de renommée mondiale",
    teaserEn:
      "Exclusive distribution of world-renowned footwear, apparel and accessories brands",
    logo: justfootLogo,
    cover: justfootCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "lady card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger", "Oran", "Sétif"],
    hasWebsite: 0,
  },
  {
    id: 52,
    name: "Bleu Blanc",
    teaser: "Hypermarché",
    teaserEn: "Hypermarket",
    logo: bleuBlancLogo,
    cover: bleuBlancCover,
    categories: ["Hypermarchés"],
    includedGiftCardTypes: ["limitless card", "ramadhaniya card", "bts card"],
    wilayas: ["Oran"],
    hasWebsite: 0,
  },
  {
    id: 53,
    name: "Hypermarché le T/Touati",
    teaser: "Hypermarché",
    teaserEn: "Hypermarket",
    logo: touattiLogo,
    cover: touattiCover,
    categories: ["Hypermarchés"],
    includedGiftCardTypes: ["limitless card", "ramadhaniya card", "bts card"],
    wilayas: ["Sétif"],
    hasWebsite: 0,
  },
  {
    id: 54,
    name: "Gresley",
    teaser:
      "Prêt-à-porter et sur mesure pour les hommes qui cherchent l’élégance",
    teaserEn: "Ready to wear and made-to-measure for men who seek elegance",
    logo: gresleyLogo,
    cover: gresleyCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 78,
    name: "IRON PARK",
    teaser: "Complexe sportif et de loisirs proposant huit activités",
    teaserEn: "Sports and entertainment complex offering eight activities",
    logo: ironParkLogo,
    cover: ironParkCover,
    categories: ["Loisir", "Salle de sport"],
    includedGiftCardTypes: ["limitless card", "move&relax card", "lady card"],
    wilayas: ["Blida"],
    hasWebsite: 0,
  },
  {
    id: 137,
    name: "SHAPES",
    teaser: "Espace dédié au sport, à la santé et au bien être",
    teaserEn: "Dedicated space for sports, health, and wellnesss",
    logo: shapesLogo,
    cover: shapesCover,
    categories: ["Loisir", "Salle de sport", "SPA Bien-être"],
    includedGiftCardTypes: ["limitless card", "move&relax card", "lady card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 92,
    name: "Thuraya Land",
    teaser: "Librairie, jouets et articles scolaires",
    teaserEn: "Bookshop, toys and school supplies",
    logo: thurayaLandLogo,
    cover: thurayaLandCover,
    categories: ["Loisir"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Alger", "Oran"],
    hasWebsite: 0,
  },
  {
    id: 132,
    name: "HUGE STATIONERY",
    teaser: "Magasin de fournitures scolaires",
    teaserEn: "School supplies store",
    logo: hugeLogo,
    cover: hugeCover,
    categories: ["Loisir"],
    includedGiftCardTypes: ["limitless card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 129,
    name: "Oscar",
    teaser: "Librairie, Papeterie et jouets",
    teaserEn: "Bookstore, Stationery and Toys",
    logo: oscarLogo,
    cover: oscarCover,
    categories: ["Loisir"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Tlemcen"],
    hasWebsite: 0,
  },
  {
    id: 98,
    name: "STAR PEINTURE",
    teaser: "Fabrication et distribution des peintures",
    teaserEn: "Paint manufacture and distribution",
    logo: starPeintureLogo,
    cover: starPeintureCover,
    categories: ["Outillage"],
    includedGiftCardTypes: ["limitless card"],
    wilayas: ["Alger", "Blida"],
    hasWebsite: 0,
  },
  /*
  {
    id: 55,
    name: "Flex On Club99",
    teaser: "Complexe sportif multi disciplines",
    teaserEn: "Multi-sport complex",
    logo: flexClub99Logo,
    cover: flexClub99Cover,
    categories: ["Salle de sport"],
    includedGiftCardTypes: ["limitless card", "move&relax card", "lady card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 56,
    name: "Dojo",
    teaser:
      "Centre de remise en forme : Musculation, Crossfit, Cardio, Fitness, Boxe, Jiu-jitsu et Détente",
    teaserEn:
      "Fitness center: Bodybuilding, Crossfit, Cardio, Fitness, Boxing, Jiu-jitsu and Relaxation",
    logo: dojoLogo,
    cover: dojoCover,
    categories: ["Salle de sport"],
    includedGiftCardTypes: ["limitless card", "move&relax card", "lady card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },*/
  {
    id: 73,
    name: "My Fit Box",
    teaser: "Salle de sport et centre de remise en forme",
    teaserEn: "Gym and fitness center",
    logo: myfitboxLogo,
    cover: myfitboxCover,
    categories: ["Salle de sport"],
    includedGiftCardTypes: ["limitless card", "move&relax card", "lady card"],
    wilayas: ["Constantine"],
    hasWebsite: 0,
  },
  {
    id: 74,
    name: "Metal Sport Boukli",
    teaser: "Salle de sport et centre de remise en forme",
    teaserEn: "Gym and fitness center",
    logo: metalSportLogo,
    cover: metalSportCover,
    categories: ["Salle de sport"],
    includedGiftCardTypes: ["limitless card", "move&relax card", "lady card"],
    wilayas: ["Oran"],
    hasWebsite: 0,
  },
  {
    id: 79,
    name: "Alpha Gym",
    teaser: "Salle de sport et centre de remise en forme",
    teaserEn: "Gym and fitness center",
    logo: alphaGymLogo,
    cover: alphaGymCover,
    categories: ["Salle de sport"],
    includedGiftCardTypes: ["limitless card", "move&relax card", "lady card"],
    wilayas: ["Annaba"],
    hasWebsite: 0,
  },
  {
    id: 57,
    name: "La cigogne d’ailleurs",
    teaser:
      "Entreprise de fabrication de meubles et objets de décoration au design tendance et dans l'air du temps",
    teaserEn:
      "Company manufacturing furniture and decorative objects with trendy design and in the air of time",
    logo: laCigogneDailleurLogo,
    cover: laCigogneDailleurCover,
    categories: ["Déco"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 58,
    name: "Cardinal Market",
    teaser: "Hypermarché",
    teaserEn: "Hypermarket",
    logo: cardinalLogo,
    cover: cardinalCover,
    categories: ["Hypermarchés"],
    includedGiftCardTypes: ["limitless card", "ramadhaniya card", "bts card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 59,
    name: "Maison de la cravate",
    teaser:
      "Un univers dédié à la cravate. Des cravates sous toutes les formes et les couleurs",
    teaserEn: "A universe dedicated to ties. Ties in all shapes and colors",
    logo: maisonDeLaCravateLogo,
    cover: maisonDeLaCravateCover,
    categories: ["Accessoires"],
    includedGiftCardTypes: ["limitless card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 60,
    name: "Paul & Shark",
    teaser: "Marque italienne de vêtements de luxe",
    teaserEn: "Italian luxury clothing brand",
    logo: paulSharkLogo,
    cover: paulSharkCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "ramadhaniya card",
    ],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 100,
    name: "Maison du savon",
    teaser: "Produits naturels de cosmétiques",
    teaserEn: "Natural cosmetics",
    logo: maisonDuSavonLogo,
    cover: maisonDuSavonCover,
    categories: ["Cosmétique"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Alger", "Oran"],
    hasWebsite: 0,
  },
  {
    id: 61,
    name: "Lima Care",
    teaser: "Produits parapharmaceutiques et cosmétiques",
    teaserEn: "Parapharmaceutical and cosmetic products",
    logo: limaCareLogo,
    cover: limaCareCover,
    categories: ["Cosmétique"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Oran"],
    hasWebsite: 1,
  },
  {
    id: 99,
    name: "ERYAM",
    teaser:
      "Produits de soins cosmétiques naturels inspirée par les rituels de beauté traditionnels algériens et la clean beauty",
    teaserEn:
      "Natural cosmetic skincare products inspired by traditional Algerian beauty rituals and clean beauty",
    logo: eryamLogo,
    cover: eryamCover,
    categories: ["Cosmétique"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: [],
    hasWebsite: 1,
  },
  {
    id: 62,
    name: "Parapharm Tarzaali",
    teaser: "Parapharmacie",
    teaserEn: "Parapharmacy",
    logo: parapharmTarzaali,
    cover: parapharmTarzaaliCover,
    categories: ["Cosmétique"],
    includedGiftCardTypes: ["limitless card", "lady card"],
    wilayas: ["Blida"],
    hasWebsite: 1,
  },
  {
    id: 63,
    name: "Sneaksa",
    teaser: "Sportswear pour toute la famille",
    teaserEn: "Sportswear for the whole family",
    logo: sneaksaLogo,
    cover: sneaksaCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: [
      "limitless card",
      "readytowear card",
      "ramadhaniya card",
    ],
    wilayas: [],
    hasWebsite: 1,
  },
  {
    id: 65,
    name: "Majestic World Center",
    teaser: "Supermarché",
    teaserEn: "Supermarket",
    logo: majesticWorldCenterLogo,
    cover: majesticWorldCenterCover,
    categories: ["Hypermarchés"],
    includedGiftCardTypes: ["limitless card", "ramadhaniya card", "bts card"],
    wilayas: ["Constantine"],
    hasWebsite: 0,
  },
  {
    id: 66,
    name: "Oxygène Fitness Club",
    teaser: "Salle adaptée pour tous vos besoins sportifs",
    teaserEn: "Room adapted for all your sporting needs",
    logo: oxygenLogo,
    cover: oxygenCover,
    categories: ["Salle de sport"],
    includedGiftCardTypes: ["limitless card", "move&relax card", "lady card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },

  {
    id: 67,
    name: "Extra électromenager",
    teaser:
      "Vente des équipements de cuisine encastrable et le petit électroménager, image et son",
    teaserEn:
      "Sale of built-in kitchen equipment and small household appliances, TV and audio",
    logo: extraElectromenagerLogo,
    cover: extraElectromenagerCover,
    categories: ["Electroménager"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Oran", "Alger"],
    hasWebsite: 0,
  },
  /*
  {
    id: 138,
    name: "Nardi",
    teaser: "Marque italienne d'électroménagers",
    teaserEn: "Italian brand of household appliances",
    logo: nardiLogo,
    cover: nardiCover,
    categories: ["Electroménager"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  */
  {
    id: 68,
    name: "ARMANI EXCHANGE",
    teaser: "La mode décontractée et moderne",
    teaserEn: "Casual and modern fashion",
    logo: armaniExchangeLogo,
    cover: armaniExchangeCover,
    categories: ["Prêt-à-porter"],
    includedGiftCardTypes: ["limitless card", "readytowear card", "lady card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 69,
    name: "City Foot",
    teaser: "Football en salle, salle de fitness, restauration",
    teaserEn: "Indoor soccer, fitness room, catering",
    logo: cityFootLogo,
    cover: cityFootCover,
    categories: ["Salle de sport"],
    includedGiftCardTypes: ["limitless card", "move&relax card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 70,
    name: "S Market",
    teaser: "Supermarché",
    teaserEn: "Supermarket",
    logo: smarketLogo,
    cover: smarketCover,
    categories: ["Hypermarchés"],
    includedGiftCardTypes: ["limitless card", "ramadhaniya card", "bts card"],
    wilayas: ["Alger"],
    hasWebsite: 0,
  },
  {
    id: 139,
    name: "Priba",
    teaser: "Supermarché",
    teaserEn: "Supermarket",
    logo: pribaLogo,
    cover: pribaCover,
    categories: ["Hypermarchés"],
    includedGiftCardTypes: ["limitless card", "ramadhaniya card", "bts card"],
    wilayas: ["Tizi Ouzou"],
    hasWebsite: 0,
  },
  {
    id: 71,
    name: "Kenzi New Home",
    teaser: "Vente de produits d'électroménager multimarques",
    teaserEn: "Sale of multi-brand household appliances",
    logo: kenziLogo,
    cover: kenziCover,
    categories: ["Electroménager"],
    includedGiftCardTypes: ["limitless card", "lady card", "ramadhaniya card"],
    wilayas: ["Alger", "Blida"],
    hasWebsite: 0,
  },
];

export const wilayas = [
  {
    id: "1",
    code: "1",
    name: "Adrar",
    ar_name: "أدرار",
    longitude: "27.9766155",
    latitude: "-0.20396",
  },
  {
    id: "2",
    code: "2",
    name: "Chlef",
    ar_name: "الشلف",
    longitude: "36.1691245",
    latitude: "1.3539002",
  },
  {
    id: "3",
    code: "3",
    name: "Laghouat",
    ar_name: "الأغواط",
    longitude: "33.7873735",
    latitude: "2.8829115",
  },
  {
    id: "4",
    code: "4",
    name: "Oum El Bouaghi",
    ar_name: "أم البواقي",
    longitude: "35.8726014",
    latitude: "7.1180248",
  },
  {
    id: "5",
    code: "5",
    name: "Batna",
    ar_name: "باتنة",
    longitude: "35.32147",
    latitude: "3.1066502",
  },
  {
    id: "6",
    code: "6",
    name: "Béjaïa",
    ar_name: "بجاية",
    longitude: "36.7695969",
    latitude: "5.0085855",
  },
  {
    id: "7",
    code: "7",
    name: "Biskra",
    ar_name: "بسكرة",
    longitude: "34.8515041",
    latitude: "5.7246709",
  },
  {
    id: "8",
    code: "8",
    name: "Bechar",
    ar_name: "بشار",
    longitude: "31.5977602",
    latitude: "-1.8540446",
  },
  {
    id: "9",
    code: "9",
    name: "Blida",
    ar_name: "البليدة",
    longitude: "36.4803023",
    latitude: "2.8009379",
  },
  {
    id: "10",
    code: "10",
    name: "Bouira",
    ar_name: "البويرة",
    longitude: "36.2084234",
    latitude: "3.925049",
  },
  {
    id: "11",
    code: "11",
    name: "Tamanrasset",
    ar_name: "تمنراست",
    longitude: "22.2746227",
    latitude: "5.6754684",
  },
  {
    id: "12",
    code: "12",
    name: "Tbessa",
    ar_name: "تبسة",
    longitude: "35.4117259",
    latitude: "8.110545",
  },
  {
    id: "13",
    code: "13",
    name: "Tlemcen",
    ar_name: "تلمسان",
    longitude: "34.8959541",
    latitude: "-1.3150979",
  },
  {
    id: "14",
    code: "14",
    name: "Tiaret",
    ar_name: "تيارت",
    longitude: "35.3599899",
    latitude: "1.3916159",
  },
  {
    id: "15",
    code: "15",
    name: "Tizi Ouzou",
    ar_name: "تيزي وزو",
    longitude: "36.7002068",
    latitude: "4.075957",
  },
  {
    id: "16",
    code: "16",
    name: "Alger",
    ar_name: "الجزائر",
    longitude: "36.7538259",
    latitude: "3.057841",
  },
  {
    id: "17",
    code: "17",
    name: "Djelfa",
    ar_name: "الجلفة",
    longitude: "34.6672467",
    latitude: "3.2993118",
  },
  {
    id: "18",
    code: "18",
    name: "Jijel",
    ar_name: "جيجل",
    longitude: "36.7962714",
    latitude: "5.7504845",
  },
  {
    id: "19",
    code: "19",
    name: "Sétif",
    ar_name: "سطيف",
    longitude: "36.1905173",
    latitude: "5.4202134",
  },
  {
    id: "20",
    code: "20",
    name: "Saefda",
    ar_name: "سعيدة",
    longitude: "34.841945",
    latitude: "0.1483583",
  },
  {
    id: "21",
    code: "21",
    name: "Skikda",
    ar_name: "سكيكدة",
    longitude: "36.8777912",
    latitude: "6.9357204",
  },
  {
    id: "22",
    code: "22",
    name: "Sidi Bel Abbes",
    ar_name: "سيدي بلعباس",
    longitude: "35.206334",
    latitude: "-0.6301368",
  },
  {
    id: "23",
    code: "23",
    name: "Annaba",
    ar_name: "عنابة",
    longitude: "36.9184345",
    latitude: "7.7452755",
  },
  {
    id: "24",
    code: "24",
    name: "Guelma",
    ar_name: "قالمة",
    longitude: "36.4569088",
    latitude: "7.4334312",
  },
  {
    id: "25",
    code: "25",
    name: "Constantine",
    ar_name: "قسنطينة",
    longitude: "36.319475",
    latitude: "6.7370571",
  },
  {
    id: "26",
    code: "26",
    name: "Médea",
    ar_name: "المدية",
    longitude: "36.2838408",
    latitude: "2.7728462",
  },
  {
    id: "27",
    code: "27",
    name: "Mostaganem",
    ar_name: "مستغانم",
    longitude: "35.9751841",
    latitude: "0.1149273",
  },
  {
    id: "28",
    code: "28",
    name: "M'Sila",
    ar_name: "المسيلة",
    longitude: "35.7211476",
    latitude: "4.5187283",
  },
  {
    id: "29",
    code: "29",
    name: "Mascara",
    ar_name: "معسكر",
    longitude: "35.382998",
    latitude: "0.1542592",
  },
  {
    id: "30",
    code: "30",
    name: "Ouargla",
    ar_name: "ورقلة",
    longitude: "32.1961967",
    latitude: "4.9634113",
  },
  {
    id: "31",
    code: "31",
    name: "Oran",
    ar_name: "وهران",
    longitude: "35.7066928",
    latitude: "-0.6405861",
  },
  {
    id: "32",
    code: "32",
    name: "El Bayadh",
    ar_name: "البيض",
    longitude: "32.5722756",
    latitude: "0.950011",
  },
  {
    id: "33",
    code: "33",
    name: "Illizi",
    ar_name: "إليزي",
    longitude: "26.5065999",
    latitude: "8.480587",
  },
  {
    id: "34",
    code: "34",
    name: "Bordj Bou Arreridj",
    ar_name: "برج بوعريريج",
    longitude: "36.0686488",
    latitude: "4.7691823",
  },
  {
    id: "35",
    code: "35",
    name: "Boumerdes",
    ar_name: "بومرداس",
    longitude: "36.7564181",
    latitude: "3.4917212",
  },
  {
    id: "36",
    code: "36",
    name: "El Tarf",
    ar_name: "الطارف",
    longitude: "36.7534258",
    latitude: "8.2984543",
  },
  {
    id: "37",
    code: "37",
    name: "Tindouf",
    ar_name: "تندوف",
    longitude: "27.2460501",
    latitude: "-6.3252899",
  },
  {
    id: "38",
    code: "38",
    name: "Tissemsilt",
    ar_name: "تيسمسيلت",
    longitude: "35.6021906",
    latitude: "1.802187",
  },
  {
    id: "39",
    code: "39",
    name: "El Oued",
    ar_name: "الوادي",
    longitude: "33.3714492",
    latitude: "6.8573436",
  },
  {
    id: "40",
    code: "40",
    name: "Khenchela",
    ar_name: "خنشلة",
    longitude: "35.4263293",
    latitude: "7.1414137",
  },
  {
    id: "41",
    code: "41",
    name: "Souk Ahras",
    ar_name: "سوق أهراس",
    longitude: "36.277849",
    latitude: "7.9592299",
  },
  {
    id: "42",
    code: "42",
    name: "Tipaza",
    ar_name: "تيبازة",
    longitude: "36.5980966",
    latitude: "2.4085379",
  },
  {
    id: "43",
    code: "43",
    name: "Mila",
    ar_name: "ميلة",
    longitude: "36.4514882",
    latitude: "6.2487316",
  },
  {
    id: "44",
    code: "44",
    name: "Ain Defla",
    ar_name: "عين الدفلى",
    longitude: "36.1283915",
    latitude: "2.1772514",
  },
  {
    id: "45",
    code: "45",
    name: "Naama",
    ar_name: "النعامة",
    longitude: "33.1995605",
    latitude: "-0.8021968",
  },
  {
    id: "46",
    code: "46",
    name: "Ain Temouchent",
    ar_name: "عين تموشنت",
    longitude: "35.404044",
    latitude: "-1.0580975",
  },
  {
    id: "47",
    code: "47",
    name: "Ghardaefa",
    ar_name: "غرداية",
    longitude: "32.5891743",
    latitude: "3.7455655",
  },
  {
    id: "48",
    code: "48",
    name: "Relizane",
    ar_name: "غليزان",
    longitude: "35.8050195",
    latitude: "0.867381",
  },
  {
    id: "49",
    code: "49",
    name: "El M'ghair",
    ar_name: "المغير",
    longitude: "33.947222",
    latitude: "5.922222",
  },
  {
    id: "50",
    code: "50",
    name: "El Menia",
    ar_name: "المنيعة",
    longitude: "30.579167",
    latitude: "2.879167",
  },
  {
    id: "51",
    code: "51",
    name: "Ouled Djellal",
    ar_name: "أولاد جلال",
    longitude: "34.433333",
    latitude: "5.066667",
  },
  {
    id: "52",
    code: "52",
    name: "Bordj Baji Mokhtar",
    ar_name: "برج باجي مختار",
    longitude: "21.327778",
    latitude: "0.955556",
  },
  {
    id: "53",
    code: "53",
    name: "Béni Abbès",
    ar_name: "بني عباس",
    longitude: "30.133333",
    latitude: "-2.166667",
  },
  {
    id: "54",
    code: "54",
    name: "Timimoun",
    ar_name: "تيميمون",
    longitude: "29.258333",
    latitude: "0.230556",
  },
  {
    id: "55",
    code: "55",
    name: "Touggourt",
    ar_name: "تقرت",
    longitude: "33.108333",
    latitude: "6.063889",
  },
  {
    id: "56",
    code: "56",
    name: "Djanet",
    ar_name: "جانت",
    longitude: "24.554167",
    latitude: "9.484722",
  },
  {
    id: "57",
    code: "57",
    name: "In Salah",
    ar_name: "عين صالح",
    longitude: "27.197222",
    latitude: "2.483333",
  },
  {
    id: "58",
    code: "58",
    name: "In Guezzam",
    ar_name: "عين قزام",
    longitude: "19.572222",
    latitude: "5.769444",
  },
];

export const categories = [
  "Toutes",
  "Prêt-à-porter",
  "Enfant",
  "Electroménager",
  "Electronique",
  "Accessoires",
  "Montres",
  "Déco",
  "Cosmétique",
  "Salle de sport",
  "SPA Bien-être",
  "Hypermarchés",
  "Formations",
  "Loisir",
  "Outillage",
  "Autre",
];
