import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PartnerCard from "./partnerCard";
import { partners, wilayas, categories } from "./partnersData";
import {
  PartnersWrapper,
  InlineFilters,
  EmtyStateContainer,
  FilterWrapper,
  FilterLabel,
  ResearchInput,
  Select,
  Option,
  CategoriesSelect,
  CategoriesWrapper,
  CatgoriesOption,
} from "./partnersListElements";
import { Container } from "../../SharedComponents/sharedComponents";
import ReactGA from "react-ga4";

const PartnersList = () => {
  const { t } = useTranslation();
  const [partnersList, partnersListHandler] = useState(partners);
  const [enteredText, setEnteredText] = useState("");
  const [enteredWilaya, setEnteredWilaya] = useState("Toutes les wilayas");
  const [enteredCard, setEnteredCard] = useState("limitless card");
  const [enteredCategory, setEnteredCategory] = useState("Toutes");
  const [enteredDisponibility, setEnteredDisponibility] = useState("Toutes");

  const applyFilters = (text, wilaya, card, categorie, disponibility) => {
    return partners.filter((partner) => {
      let result =
        partner.name.toLowerCase().includes(text.toLowerCase()) ||
        partner.teaser.toLowerCase().includes(text.toLowerCase()) ||
        partner.teaserEn.toLowerCase().includes(text.toLowerCase());
      if (!result) {
        return result;
      }

      if (categorie !== "Toutes") {
        result = result && partner.categories.includes(categorie);
        if (!result) {
          return result;
        }
      }

      if (wilaya !== "Toutes les wilayas") {
        result =
          result &&
          (partner.hasWebsite === 1 || partner.wilayas.includes(wilaya));
        if (!result) {
          return result;
        }
      }

      if (disponibility !== "Ne pas spécifier") {
        if (disponibility === "en ligne") {
          result = result && partner.hasWebsite === 1;
        }
        if (disponibility === "en magasin") {
          result = result && ((partner.wilayas.includes(wilaya) && wilaya !== "Toutes les wilayas") || (partner.wilayas.length >= 1 && wilaya === "Toutes les wilayas"));
        }
        if (!result) {
          return result;
        }
      }

      return result && partner.includedGiftCardTypes.includes(card);
    });
  };

  const textChangeHandler = (event) => {
    ReactGA.event({
      category: "List of Partners Section From Partners Page",
      action: "Applying Filters to the partners list",
      label: "Search",
    });
    setEnteredText(event.target.value);
    partnersListHandler(
      applyFilters(
        event.target.value,
        enteredWilaya,
        enteredCard,
        enteredCategory,
        enteredDisponibility
      )
    );
  };

  const disponibilityChangeHandler = (event) => {
    ReactGA.event({
      category: "List of Partners Section From Partners Page",
      action: "Applying Filters to the partners list",
      label: "Disponibility",
    });
    setEnteredDisponibility(event.target.value);
    partnersListHandler(
      applyFilters(
        enteredText,
        enteredWilaya,
        enteredCard,
        enteredCategory,
        event.target.value
      )
    );
  };

  const wilayaChangeHandler = (event) => {
    ReactGA.event({
      category: "List of Partners Section From Partners Page",
      action: "Applying Filters to the partners list",
      label: "wilayas",
    });
    setEnteredWilaya(event.target.value);
    partnersListHandler(
      applyFilters(
        enteredText,
        event.target.value,
        enteredCard,
        enteredCategory,
        enteredDisponibility
      )
    );
  };

  const cardChangeHandler = (event) => {
    ReactGA.event({
      category: "List of Partners Section From Partners Page",
      action: "Applying Filters to the partners list",
      label: "cards",
    });
    setEnteredCard(event.target.value);
    partnersListHandler(
      applyFilters(
        enteredText,
        enteredWilaya,
        event.target.value,
        enteredCategory,
        enteredDisponibility
      )
    );
  };

  const categorieChangeHandler = (categorie) => {
    setEnteredCategory(categorie);
    partnersListHandler(
      applyFilters(
        enteredText,
        enteredWilaya,
        enteredCard,
        categorie,
        enteredDisponibility
      )
    );
  };

  return (
    <Container color={"transparent"}>
      <InlineFilters>
        <FilterWrapper>
          <FilterLabel>{t("search")}</FilterLabel>
          <ResearchInput
            type="text"
            value={enteredText}
            placeholder={t("searchPlaceholder")}
            onChange={textChangeHandler}
          />
        </FilterWrapper>

        <FilterWrapper>
          <FilterLabel>{t("cardFilter")}</FilterLabel>
          <Select value={enteredCard} onChange={cardChangeHandler}>
            <Option value="limitless card">Limitless Card</Option>
            <Option value="readytowear card">ReadyToWear Card </Option>
            <Option value="move&relax card">Move&Relax Card</Option>
            <Option value="lady card">Lady Card</Option>
            <Option value="ramadhaniya card">Ramadhaniya Card</Option>
            <Option value="azadea card">AZADEA Card</Option>
            <Option value="bts card">Back to School Card</Option>
          </Select>{" "}
        </FilterWrapper>

        <FilterWrapper>
          <FilterLabel>{t("wilayaFilter")}</FilterLabel>
          <Select value={enteredWilaya} onChange={wilayaChangeHandler}>
            <Option value="Toutes les wilayas">{t("wilayas")}</Option>
            {wilayas.map((data, index) => {
              return <Option value={data.name}>{data.name}</Option>;
            })}
          </Select>
        </FilterWrapper>
        <FilterWrapper>
          <FilterLabel>{t("disponibilityFilter")}</FilterLabel>
          <Select
            value={enteredDisponibility}
            onChange={disponibilityChangeHandler}
          >
            <Option value="Ne pas spécifier">{t("instoreOrOnline")}</Option>
            <Option value="en ligne">{t("online")}</Option>
            <Option value="en magasin">{t("instore")}</Option>
          </Select>
        </FilterWrapper>
      </InlineFilters>

      <CategoriesWrapper>
        <CategoriesSelect>
          {categories.map((data, index) => {
            return (
              <CatgoriesOption
                active={data === enteredCategory ? 1 : 0}
                onClick={() => categorieChangeHandler(data)}
              >
                {t(data)}
              </CatgoriesOption>
            );
          })}
        </CategoriesSelect>
      </CategoriesWrapper>

      {partnersList.length === 0 && (
        <EmtyStateContainer>
          <p style={{ color: "grey" }}>{t("nobrand")}</p>{" "}
        </EmtyStateContainer>
      )}
      {partnersList.length > 0 && (
        <PartnersWrapper>
          {" "}
          {partnersList.map((data, index) => {
            const nom = data.nom;
            console.log(data.website);
            return (
              <PartnerCard
                key={data.id}
                cover={data.cover}
                logo={data.logo}
                name={data.name}
                wilayas={
                  (data.wilayas.includes(enteredWilaya) &&
                    enteredWilaya !== "Toutes les wilayas") ||
                  (data.wilayas.length >= 1 &&
                    enteredWilaya === "Toutes les wilayas")
                }
                hasWebsite={data.hasWebsite}
                teaser={data.teaser}
                teaserEn={data.teaserEn}
                onClick={() =>
                  ReactGA.event({
                    category: "List of Partners Section From Partners Page",
                    action: "Partner Hover or Click" + { nom },
                  })
                }
              ></PartnerCard>
            );
          })}
        </PartnersWrapper>
      )}
    </Container>
  );
};

export default PartnersList;
