import React from 'react'
import LimitlessCard from './limitlessCard'
import ReadyToWear from './readyToWearCard'
import MoveAndRelax from './moveAndRelaxCard'
import LadyCard from './ladyCard'
import ForYou from './forYouCard'
import RamadhaniyaCard from './ramadhaniyaCard'
import BTS from "./btsCard"

const CardsSection = () => {
  return (
    <>
      <LimitlessCard id='limitless'></LimitlessCard>
      <ReadyToWear id='readytowear'></ReadyToWear>
      <MoveAndRelax id='moveandrelax'></MoveAndRelax>
      <BTS></BTS>
      <RamadhaniyaCard id='ramadhaniya'></RamadhaniyaCard>
      <LadyCard id='lady'></LadyCard>
      <ForYou id='foryou'></ForYou>
    </>

  )
}

export default CardsSection
